import React from 'react'

export default class RequiredMark extends React.Component {
  render() {
    if (!this.props.show) return null
    return (
      <span className="required-field">(*)</span>
    )
  }
}

RequiredMark.defaultProps = {
  show: true
}