import React, { Component } from 'react';
import { isPermissionGranted } from '../../helpers/NavigationHelper';

class FieldSet extends Component {

  render() {
    return (
      <fieldset disabled={!isPermissionGranted(this.props.permission)}>
        {this.props.children}
      </fieldset>
    );
  }
}

export default FieldSet;