import React from 'react'

export default class AlertContentTemplate extends React.Component {
  
  getIcon = () => {
    switch (this.props.condition) {
      case "success":
        return "far fa-check-circle"
      case "warning":
        return "fas fa-exclamation-circle"
      case "error":
        return "fas fa-times-circle"
      case "info":
        return "fas fa-info-circle"
      default:
        return ""
    }
  }
  
  render() {
    return (
      <div className={this.props.classNames} id={this.props.id} style={this.props.styles}>
        <div className='s-alert-box-inner'>
          <i className={this.getIcon()}></i>{' '}
          {this.props.message}
        </div>
        <span className='s-alert-close' onClick={this.props.handleClose}></span>
      </div>
    )
  }
}