import About from '../components/ExhibitBooth/About';
import Resources from '../components/ExhibitBooth/Resources';
import Workshops from '../components/ExhibitBooth/Workshops';
import Reps from '../components/ExhibitBooth/Reps';
import Booth from '../components/ExhibitBooth/Booth';
import Offers from '../components/ExhibitBooth/Offers';
import Contact from '../components/ExhibitBooth/Contact';
import More from '../components/ExhibitBooth/More';
import { hasContactInformation } from '../helpers/CompanyHelper';
import { calendar_week, representative, workshops } from '../assets/Icons';
import scheduleHelper from '../helpers/ScheduleHelper';

export function boothNav() {
  return [
    {
      name: 'About',
      url: '/about',
      icon: 'fa fa-info',
      visible: (c) => c.ShortDescription || c.Description
    },
    {
      name: 'Resources',
      url: '/resources',
      icon: 'fas fa-photo-video',
      visible: (c) => c.Files.length > 0
    },
    {
      name: 'Representatives',
      url: '/reps',
      icon: representative,
      visible: (c) => c.Rep
    },
    {
      name: 'Sessions',
      url: '/sessions',
      icon: workshops,
      visible: (c) => c.HasWorkshops
    },

    {
      name: 'Schedule at a Glance',
      url: '/schedule',
      icon: calendar_week,
      visible: scheduleHelper.publicationStatus,
    },

    {
      name: 'Booth Information',
      url: '/information',
      icon: "fas fa-store",
      visible: (c) => c.Booth
    },
    {
      name: 'Offers',
      url: '/offers',
      icon: 'fas fa-trophy',
      visible: (c) => c.Offers
    },
    {
      name: 'Contact',
      url: '/contact',
      icon: 'far fa-id-card',
      visible: (c) => hasContactInformation(c)
    },
    {
      name: 'More Options',
      url: '/more',
      icon: 'fas fa-ellipsis-v'
    }
  ]
}

export const routes = [
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/resources',
    name: 'Resources',
    component: Resources,
  },
  {
    path: '/reps',
    name: 'Representatives',
    component: Reps,
  },
  {
    path: '/sessions',
    name: 'Sessions',
    component: Workshops,
  },
  {
    path: '/information',
    name: 'Booth Information',
    component: Booth,
  },
  {
    path: '/offers',
    name: 'Offers',
    component: Offers,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/more',
    name: 'More Options',
    component: More,
  }
]