import {
  SESSION_LOGIN_REQUEST,
  SESSION_LOGIN_SUCCESS,
  SESSION_LOGIN_FAILURE,
  SESSION_LOGOUT_REQUEST,
  SESSION_LOGOUT_SUCCESS,
  SESSION_LOGOUT_FAILURE
}
from '../actionTypes'

const initialState = {
  active: true,
  loading: false,
  errorMessage: "",
  domain: "",
  userName: ""
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SESSION_LOGIN_REQUEST:
      return {
        ...state,
        errorMessage: "",
        loading: true
      }
    case SESSION_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        active: true,
        ...action.payload
      }
    case SESSION_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        active: false,
        ...action.payload
      }
    case SESSION_LOGOUT_REQUEST:
      return {
        ...state,
        errorMessage: "",
        loading: true
      }
    case SESSION_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        active: false
      }
    case SESSION_LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        active: false,
        ...action.payload
      }
    default:
      return state;
  }
}
