import React from 'react'

import steady from '../assets/img/loading-ct-heartbeat.gif';
import animated from '../assets/img/logo-animated.svg';

import { isEdge, isIE } from '../helpers/CompatibilityHelper';

class Loading extends React.Component {
  render() {
    
    const useAnimation = !isEdge && !isIE;
    const width = this.props.width + (!useAnimation ? 10 : 0);
    const height = this.props.height + (!useAnimation ? 10 : 0);
    const margin = !useAnimation ? 0 : 5;
    
    const image = useAnimation ? animated : steady;

    const img = <img src={image} style={{width: width, height: height, margin: margin}} alt="Loading..."/>

    if (this.props.noContainer) {
      return img
    }

    return (
      <div className={`text-center animated fadeIn pt-1 ${this.props.className || ""}`}>
        {img}
      </div>
    )
  }
}

Loading.defaultProps = {
  width: 40,
  height: 40
}

export default Loading;
