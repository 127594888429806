import React, { Component } from 'react';

import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import Alert from 'react-s-alert';

import serverAPI from '../../serverAPI';

class Offers extends Component {

  apply = () => {
    serverAPI("company.acceptoffer", "post", { company: this.props.company.Id })
    .then(() => Alert.success("Request sent! You will get an update if you win."))
    .catch(e => Alert.error(e.message))
  }

  render() {
    return (
      <>
        <Card>
          <CardHeader className="bg-primary text-center">
            Offers
        </CardHeader>
          <CardBody>
            {this.renderOffers(this.props.company.Offers)}
          </CardBody>
        </Card>
        {this.props.company.Offers &&
          <Button block color="success" onClick={this.apply}>Get Offer</Button>
        }
      </>
    )
  }

  renderOffers = (offers) => {
    if (!offers) {
      return <div className="text-secondary">No offers available</div>
    }
    return offers
  }
}

export default Offers