import securityHelper from "./SecurityHelper"

export const PREMIUM_PACKAGE = "9D5A5BDE-B21A-4BB9-8084-4E89E24BED4A"
export const BASIC_PACKAGE = "3544B645-A2F0-46CC-A436-723016B367DF"
export const EXPO_PACKAGE = "3DAB4BC9-292B-4753-AB39-CCBF9C156D96" 

class PlanHelper { 

  prepareMenu(menuItems) {   

    if(this.isExpoPlan())
    { 
        let conferenceMenu = menuItems.find(x=> x.tag === 'conference')
        if(conferenceMenu && conferenceMenu.children)
        {  
            conferenceMenu.children.forEach(function(child){
                child.visible = child.name === "Setup" || child.name === "Staff" || child.name === "Landing Page" || 
                                child.name === "Schedule at a Glance" ;
                if(child.name === "Staff" && child.children)
                {  
                  child.children.forEach(function(child){
                        child.visible = child.name === "Administrators" 
                    });
                } 
            });
        } 

        let atendanceMenu = menuItems.find(x=> x.tag === 'attendance')
        if(atendanceMenu)
        {  
            atendanceMenu.visible = false
        } 

        let certificatesMenu = menuItems.find(x=> x.tag === 'certificates')
        if(certificatesMenu)
        {  
            certificatesMenu.visible = false
        } 

        let transcriptsMenu = menuItems.find(x=> x.tag === 'transcripts')
        if(transcriptsMenu)
        {  
            transcriptsMenu.visible = false
        }  

        let networkMenu = menuItems.find(x=> x.tag === 'social')
        if(networkMenu)
        {  
          networkMenu.visible = false
        }  
    } 

    return menuItems
  }  

  isPremiumPlan () {
    const currentPlan = this.getCurrentPlan()
    return currentPlan.toLowerCase() === PREMIUM_PACKAGE.toLowerCase()
  }

  isBasicPlan () {
    const currentPlan = this.getCurrentPlan()
    return currentPlan.toLowerCase() === BASIC_PACKAGE.toLowerCase()
  }

  isExpoPlan () {
    const currentPlan = this.getCurrentPlan()
    return currentPlan.toLowerCase() === EXPO_PACKAGE.toLowerCase()
  }
 
  getCurrentPlan()
  {
     const user = securityHelper.getUser()    
     if(user)
      return user.LicensePlanId
    return "";
  }

}

const planHelper = new PlanHelper()
export default planHelper