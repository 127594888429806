import React, { Component } from 'react';
import { Card, CardBody, CardHeader, DropdownItem } from 'reactstrap';
import serverAPI from '../../serverAPI';
import Loading from '../Loading';
import UserRow from '../User/UserRow';

class Reps extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reps: []
    }
  }

  componentDidMount() {
    this.loadPresenters()
  }

  componentDidUpdate(prevProps) {
    if (this.props.company.Id !== prevProps.company.Id) {
      this.loadPresenters()
    }
  }

  loadPresenters = () => {
    this.setState({ loading: true })
    serverAPI("company.listreps", "get", { company: this.props.company.Id, withleads: false, showdefaultphoto: false })
      .then(r => this.setState({ reps: r.results, loading: false }))
      .catch(() => { })
  }

  scheduleAppointment = (rep) => {
    this.props.history.push(`/appointments/edit?uid=${rep.Id}&name=${rep.FullName}`)
  }

  render() {

    return (
      <Card>
        <CardHeader className="bg-primary text-center">
          Representatives
        </CardHeader>
        <CardBody>
          {this.renderReps()}
        </CardBody>
      </Card>
    )
  }

  renderReps = () => {
    if (this.state.loading)
      return <div className="text-center"><Loading /></div>

    if (this.state.reps.length === 0)
      return <div className="text-secondary text-center">No reps available</div>

    return this.state.reps.map((p, i) => (
      <UserRow
        key={`rep_${i}`}
        user={p}
        className="mb-2">
        {this.props.company.LeadsAppEnabled &&
          <DropdownItem onClick={() => this.scheduleAppointment(p)}>Schedule Appointment</DropdownItem>
        }
      </UserRow>
    ))
  }
}

export default Reps