import React from 'react'

import { Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { isPermissionGranted } from '../helpers/NavigationHelper';

import $ from 'jquery';

import classnames from 'classnames'

class FooterActionBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      staticVisible: true
    }
  }

  componentDidMount() {
    this.registerListeners();
  }

  componentWillUnmount() {
    this.unregisterListeners();
  }

  registerListeners() {
    document.addEventListener('scroll', this.toggleShowStatic);
    window.addEventListener('resize', this.toggleShowStatic);

    this.toggleShowStatic();
  }

  unregisterListeners() {
    document.removeEventListener('scroll', this.toggleShowStatic);
    window.removeEventListener('resize', this.toggleShowStatic);
  }

  fixAsidePositioning = () => {
    
    if(this.props.isFromPreview){
      $("div.floating-footer-action-bar").css({ display: 'none'})
    }
    else
    {
      const numberRight = 360 + parseInt($("aside.aside-menu").css("marginRight"))
      $("div.floating-footer-action-bar").css({right: numberRight + "px"})
    }
  }

  toggleShowStatic = () => {    

    if (!this.props.showStatic) return;
    const container = document.getElementById("static-footer-bar");
    if(!container)return;
    if (!container.lastChild) return;

    const staticVisible = container.lastChild.getBoundingClientRect().top <= window.innerHeight;

    this.setState({ staticVisible }, this.fixAsidePositioning);
  }

  render() {
    return (
      <>
        <div id="static-footer-bar">
          {this.renderBar(this.props.alignRight, true)}
        </div>
        {
          !this.state.staticVisible &&
          <div className={classnames("floating-footer-action-bar", this.props.floatingClassNames)} >
            {this.renderBar(this.props.alignRightWhenFloating, false)}
          </div>
        }
      </>
    )
  }

  renderBar = (alignRight, fixed) => {
    const items = React.Children.toArray(this.props.children).filter(item => isPermissionGranted(item.props.permission))
    if (items.length === 0) return null

    return (
      <>
        {!fixed && this.props.floatingHeader}
        <Row className={`${alignRight ? "justify-content-end" : ""} no-gutters ${this.props.className}`}>
          {
            items.map((child, i) => {
              return (
                <Col key={`button_${i}`} xs="12" sm="auto" className={`mb-2 mr-0 ${i < items.length - 1 ? "mr-sm-2" : ""}`}>
                  {child}
                </Col>
              )
            })
          }
        </Row>
      </>
    );
  }
}

FooterActionBar.defaultProps = {
  className: "",
  alignRight: true,
  alignRightWhenFloating: true,
  showStatic: true
}

export default withRouter(FooterActionBar);
