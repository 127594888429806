import Alert from 'react-s-alert'

import serverAPI from '../serverAPI';
import { hasSocialProfile } from './SocialNetworkHelper';

export function deleteCompany(id) {
  return serverAPI("company.delete", "post", {id: id});
}

export function flag(id){
  return serverAPI("company.reportabuse", "post", {companyid: id});
}

export function requestInfo(id){
  serverAPI("company.requestinfo", "post", {company: id}).then(() => {
    Alert.success("We have sent your contact data to the company so they can reach you out soon.")
  })
  .catch(e => {
    Alert.error(e.message)
  });
}

export function hasContactInformation(company){
  return company.Website || company.PhoneNumber || company.StreetAddress || company.Email || hasSocialProfile(company)
}


