export const ROLE_SUPER_ADMIN_ID = "bade580f-14e6-4d7d-9715-9446cfb2f008"
export const ROLE_SALES_ID = "2914d364-591e-4225-924e-b4333912ab22"
export const ROLE_ADMIN_ID = "a17d0224-269f-4a9f-87da-b34049799bae"
export const ROLE_ATTENDEE_ID = "c8932b92-4e98-41b1-b6cd-70beb8131ccf"
export const ROLE_EXHIBITOR_ADMIN_ID = "81bf9983-e834-4984-a491-f1fd096ac6a1"
export const ROLE_EXHIBITOR_REP_ID = "bd19f903-9bbd-4855-acb2-8ef85b15a590"
export const ROLE_MODERATOR_ID = "aaaf2444-01fa-41e1-bed6-64d8006c41d6"
export const ROLE_OPERATOR_ID = "910c1e00-0832-491c-bb1e-22f38bbbf598"
export const ROLE_PRESENTER_ID = "184222e6-a404-4f75-9e3e-483d26bbe74e"

export const ROLES = [
  {id: ROLE_ADMIN_ID, label: "Administrator"},
  {id: ROLE_ATTENDEE_ID, label: "Attendee"},
  {id: ROLE_EXHIBITOR_ADMIN_ID, label: "Exhibitor Administrator"},
  {id: ROLE_EXHIBITOR_REP_ID, label: "Exhibitor Representative"},
  {id: ROLE_MODERATOR_ID, label: "Moderator"},
  {id: ROLE_OPERATOR_ID, label: "Operator"},
  {id: ROLE_PRESENTER_ID, label: "Presenter"}
]

export const EXHIBITOR_ROLES = [ 
  {id: ROLE_EXHIBITOR_ADMIN_ID, label: "Exhibitor Administrator"},
  {id: ROLE_EXHIBITOR_REP_ID, label: "Exhibitor Representative"} 
]

export const USER_ROLES = ["user", "administrator", "presenter", "attendee", "moderator", "operator"]

export const ADMIN_ROLE_IDS = [ROLE_SUPER_ADMIN_ID, ROLE_SALES_ID, ROLE_ADMIN_ID]

export function getRoleId(name) {
  const filteredRoles = ROLES.filter(r => r.label.toLowerCase() === name.toLowerCase())
  return filteredRoles.length > 0 ? filteredRoles[0].id : null
}