import React from 'react'

import PropTypes from 'prop-types'

import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'

import { Row, Col } from 'reactstrap'

import Avatar from './Controls/Avatar'

import serverAPI from '../serverAPI'
import { newGuid } from '../helpers/GuidHelper'

export class EntitySearchMenuFooter extends React.Component {
  render() {
    return (
      <li className="p-2 small text-muted">
        <i className="fa fa-search-plus"></i>{' '}
        {this.props.resultsCount === 15 ? "First 15 results are shown. " : ""}
        Type for more specific results...
      </li>
    )
  }
}

export default class EntitySearch extends React.Component {

  constructor(props) {
    super(props)

    this.taRef = React.createRef()

    this.state = {
      isLoading: false,
      options: []
    }
  }

  componentDidUpdate(prevProps) { 
    if (this.props.baseSearch !== prevProps.baseSearch) {
      this.setState({
        options: []
      }, () => {
        if (this.props.searchOnBaseChanged) {
          const taInstance = this.taRef.current
          let query = taInstance.getInput().value 
          let setFocus = true
          if(!query && this.props.resetBaseSearch)
          {
            setFocus = false
            query = ' '
          }
          if (query) { 
            this.handleSearch(query)
            if(setFocus)
              taInstance.focus()
          }
        }
      })
    }
  }

  handleFocus = e => {  
    if (this.props.searchOnFocus) {
      const query = this.taRef.current.getInput().value
      if (!query && !this.emptySearch) {
        this.emptySearch = true
        this.handleSearch("   ")
      }
    }
  }

  handleInputChange = (t, e) => {
    if (t) this.emptySearch = false
  }

  handleSearch = query => {
    this.setState({ isLoading: true, options: [] });
    if (this.props.baseSearch) query = this.props.baseSearch + " " + query
    serverAPI(this.props.searchAction, "get", { query })
      .then(res => {
        return res.results || []
      })
      .then(r => {
        this.setState({
          options: r,
          isLoading: false
        })
      })
      .catch(e => {
        console.error(e)
      })
  }

  blur() {
    this.taRef.current.blur()
  }

  handleBlur = e => {
    if (this.props.clearOnBlur && (!this.props.selected || this.props.selected.length === 0)) {
      this.taRef.current.clear()
    }
  }

  getPhotoName = (title) => {
    const components = title.split(" ");
    if (components.length < 2) {
      return title;
    }
    return [components[0], components[components.length - 1]].join(" ");
  }

  onChange = (selected) => {
    if (selected){
      selected = selected.map(s => {
        if (!s.local) {
          const sl = {...s}
          sl.local = newGuid()
          return sl
        }
        return s
      })
    }

    if (this.props.onChange) {
      this.props.onChange(selected)
    }
  }

  renderMenu = (results, menuProps) => {
    const items = results.map((result, ridx) => (
      <MenuItem key={ridx} option={result} position={ridx}>
        <Row>
          {
            this.props.showPhoto &&
            <Col xs="auto" className="pr-0">
              <Avatar src={result.Photo} name={this.getPhotoName(result.Title)} />
            </Col>
          }
          <Col className="text-truncate">
            {result.Title}
            <div className="small text-truncate">
              {result.Subtitle}
            </div>
          </Col>
        </Row>
      </MenuItem>
    ))

    return (
      <Menu {...menuProps}>
        {items}
        <EntitySearchMenuFooter resultsCount={items.length} />
      </Menu>
    );
  }

  render() {

    let selected = this.props.selected; 
    if(selected && selected.length)
    {
      selected.forEach(element => {
        element.Title = element.Title.replace("/", "\\");
      }); 
    }

    return (
      <AsyncTypeahead
        {...this.state}
        id={this.props.id}
        ref={this.taRef}
        allowNew={this.props.allowNew}
        selected={selected}
        multiple={this.props.multiple || false}
        inputProps={this.props.inputProps}
        useCache={this.props.useCache}
        labelKey="Title"
        minLength={this.props.minLength}
        onFocus={this.handleFocus}
        onInputChange={this.handleInputChange}
        onSearch={this.handleSearch}
        onChange={this.onChange}
        onBlur={this.handleBlur}
        placeholder={this.props.placeholder}
        highlightOnlyResult={!this.props.allowNew}
        filterBy={() => true}
        clearButton={this.props.clearButton}
        size={this.props.bsSize}
        renderMenu={this.props.renderMenu || this.renderMenu}
        emptyLabel={this.props.emptyLabel}
        selectHintOnEnter
      />
    )
  }
}

EntitySearch.defaultProps = {
  placeholder: "Type to search...",
  searchAction: "search",
  useCache: true,
  clearButton: true,
  searchOnBaseChanged: false,
  minLength: 0,
  // Search on focus when the underlying text is empty...
  searchOnFocus: true,
  // Clear text on blur if no valid option is selected...
  clearOnBlur: true,
  resetBaseSearch: false
}

EntitySearch.propTypes = {
  id: PropTypes.string.isRequired
};
