import React from 'react'

export default class PageTitle extends React.Component {
  render() {
    return (
      <h3 className={`${this.props.display} ${this.props.mb}`}>
        {this.props.text}
        {this.props.children}
      </h3>
    )
  }
}

PageTitle.defaultProps = {
  display: "",
  mb: "mb-4"
}