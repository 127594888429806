import * as general from '../general.js'

export var CookieHelper = function() {
};

CookieHelper.Get = function (name) {
    //construct RE to search for target name/value pair
    var re = new RegExp(name + "=[^;]+", "i");
    //if cookie found
    if (document.cookie.match(re)) {
        //return its value
        var value = document.cookie.match(re)[0].split("=")[1];
        return general.replaceAll(value, "|~|", ",");
    }
    return "";
};

CookieHelper.Set = function (name, value, expiration) {
	if (value == null) {
		CookieHelper.Remove(name);
		return;
	}
	var expStr = "";
	if (expiration) {
		expStr = ";expires=" + expiration.toUTCString();
	}
	value = general.replaceAll(value, ",", "|~|");
	document.cookie = name + "=" + value + ";path=/" + expStr;
};

CookieHelper.Remove = function(name) {
	var mydate = new Date();
	mydate.setTime(mydate.getTime() - 1);
	document.cookie = name + "=; expires=" + mydate.toGMTString();
};
