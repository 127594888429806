import React, { Component } from 'react';

class ListRowSubtitle extends Component {

    render(){
      return (
        <div className={`small text-muted ${this.props.className}`}>
          {this.props.children}
        </div>
      )
    }
}

export default ListRowSubtitle;
