import serverAPI from '../serverAPI';
import moment from "moment";
import timeZoneHelper from './TimeZoneHelper';
import securityHelper from './SecurityHelper';

class ConferenceHelper {
  constructor() {
    this.conference = null;
  }

  loadConference(forceReload) {
    if (forceReload) this.clearConference()
    if (this.conference) {
      return new Promise(r => r(this.conference))
    }
    return serverAPI("conference.next", "get", {socialfullurls: true})
      .then(res => {
        if (res.results.length) this.conference = res.results[0]
        return this.conference
      })
  }

  getConference() {
    return this.conference;
  }

  clearConference() {
    this.conference = null;
  }

  getRegistrationFormId() {
    if (!this.conference || !this.conference.RegistrationForm) return null
    return this.conference.RegistrationForm.Id
  }

  getExhibitorRegistrationFormId() {
    if (!this.conference || !this.conference.ExhibitorRegistrationForm) return null
    return this.conference.ExhibitorRegistrationForm.Id
  }

  isFeatureEnabled(feature) {
    if (!this.conference) return false
    const features = JSON.parse(this.conference.Features || "[]")
    return features.some(fid => fid === feature)
  }

  isConferenceExpired()
  {
    if(securityHelper.isEngineericaStaff()) return false
    if(!this.conference || !this.conference.ExpirationDate)  return false
    const conferenceExpirationMoment = moment.tz(this.conference.ExpirationDate, "utc"); 
    const utcNowMoment = moment.tz(timeZoneHelper.getTimeZone()); 
    return !utcNowMoment.isSameOrBefore(moment(conferenceExpirationMoment))
  }
} 


const conferenceHelper = new ConferenceHelper()
export default conferenceHelper

export const ConferenceAttendanceMode = 
{
    OnSite : 0,
    Online : 1,
    Mixed : 2
}
