import React from 'react'

import { Alert as AlertMessage } from 'reactstrap'


class Media extends React.Component {

  constructor(props) {
    super(props);

    this.videoRef = React.createRef()

    this.currentVideoTime = 0

    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    if (this.props.autoPlay && this.videoRef.current) {
      setTimeout(() => {
        if (this.videoRef.current) {
          this.videoRef.current.play()
            .then(() => {})
            .catch(err => {
              if (this.props.onAutoPlayError) {
                this.props.onAutoPlayError(err)
              }
            })
        }
      }, 1000)
    }
  }

  renderImage = () => {
    const media = this.props.media;
    var style = {};
    if (!this.props.autoHeight && this.props.maxHeight > 0) {
      style.maxHeight = this.props.maxHeight
    }
    var shine = this.state.loading ? "shine" : ""
    const className = this.props.listMode ? "img-fluid" : "img-thumbnail";
    return (
      <img
        src={media.Source}
        className={`${className} ${shine}`}
        alt={media.Thumbnail}
        style={style}
        onLoad={() => this.setState({ loading: false })}
        onError={e => {
          e.target.onError = null
          e.target.src = "/Images/image.png"
        }}
      />
    )
  }

  onTimeUpdate = e => {
    if (!this.props.onTimeUpdate) return
    this.props.onTimeUpdate(e)
  }

  onVideoTimeUpdate = e => {
    if (this.props.allowSeeking) {
      this.onTimeUpdate(e)
    }
    else if (!e.target.seeking) {
      this.currentVideoTime = e.target.currentTime
      this.onTimeUpdate(e)
    }
  }

  onVideoSeeking = e => {
    if (this.props.allowSeeking) return
    
    let delta = e.target.currentTime - this.currentVideoTime
    if (delta > 0.01) {
      e.target.currentTime = this.currentVideoTime
    }
  }

  renderVideo = () => {
    const media = this.props.media;
    var style = {};
    if (!this.props.autoHeight && this.props.maxHeight > 0) {
      style.maxHeight = this.props.maxHeight
    }

    return (
      <div className="embed-responsive embed-responsive-16by9 mb-1 text-center" style={style}>
        <video className="embed-responsive-item"
          controls={this.props.controls}
          onTimeUpdate={this.onVideoTimeUpdate}
          onSeeking={this.onVideoSeeking}
          ref={this.videoRef}
          key={this.props.key}>
          <source src={media.Source + "#t=0.1"} type="video/mp4" />
          <source src={media.Source} type="video/mp4" />
        </video>
      </div>
    )
  }

  renderFile = () => {
    const media = this.props.media;
    if (!media.Source) media.Source = media.Original
    const extension = media.Source.split(/#|\?/)[0].split('.').pop().trim();
    var icon;
    try {
      icon = require(`../assets/img/filetypes/${extension}.png`);
    } catch (err) {
      icon = require('../assets/img/filetypes/file.png');
    }

    return (
      <div className="text-center mb-2">
        <a href={media.Source} target="_blank" rel="noopener noreferrer">
          <img src={icon} alt={media.Original} style={{ maxHeight: 120 }} />
          {this.props.showName &&
            <div className="mt-3">
              {media.Original}
            </div>
          }
        </a>
      </div>
    )
  }

  renderPendingMessage = () => {
    return (
      <AlertMessage color="warning" className="d-inline-block">
        <i className="fas fa-upload"></i> File is being processed in background...
      </AlertMessage>
    )
  }

  render() {
    if (this.props.media.IsPending) {
      return this.renderPendingMessage()
    }

    const media = this.props.media;
    switch (media.Type) {
      case "image":
        return this.renderImage()
      case "video":
        return this.renderVideo()
      default:
        return this.renderFile()
    }
  }
}

Media.defaultProps = {
  autoHeight: false,
  maxHeight: 200,
  controls: true,
  allowSeeking: true,
}

export default Media