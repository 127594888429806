import React, { Component } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import serverAPI from '../../serverAPI';
import Loading from '../Loading';
import WorkshopRow from '../Workshop/WorkshopRow';

class Workshops extends Component {
  constructor(props) {
    super(props)
    this.state = {
      workshops: []
    }
  }

  componentDidMount() {
    this.loadWorkshops()
  }

  componentDidUpdate(prevProps) {
    if (this.props.company.Id !== prevProps.company.Id) {
      this.loadWorkshops()
    }
  }

  loadWorkshops = () => {
    this.setState({ loading: true })
    serverAPI("company.listworkshops", "get", { company: this.props.company.Id })
      .then(r => this.setState({ workshops: r.results, loading: false }))
      .catch(() => { })
  }

  render() {
    return (
      <Card>
        <CardHeader className="bg-primary text-center">
          Sessions
        </CardHeader>
        <CardBody>
          {this.renderWorkshops()}
        </CardBody>
      </Card>
    )
  }

  renderWorkshops = () => {
    if (this.state.loading)
      return <div className="text-center"><Loading /></div>

    if (this.state.workshops.length === 0)
      return <div className="text-secondary text-center">No sessions available</div>

    return this.state.workshops.map((w, i) => (
      <WorkshopRow
        key={`workshop_${i}`}
        workshop={w}
        className="mb-2"
      />
    ))
  }
}

export default Workshops