import React, { Component } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import MediaThumbnail from '../MediaThumbnail';

class Resources extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {

    return (
      <Card>
        <CardHeader className="bg-primary text-center">
          Resources
        </CardHeader>
        <CardBody className="d-flex flex-wrap">
          {this.renderResources()}
        </CardBody>
      </Card>
    )
  }

  renderResources = () => {
    const files = this.props.company.Files
    if (files.length === 0){
      return <div className="text-secondary">No resources available</div>
    }
    return files.map((f, index) =>
      <MediaThumbnail key={f.Id} file={f} className={index < files.length - 1 ? "mr-2" : ""} />
    )
  }
}

export default Resources