
// Strings

export function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function capitalizeAll(s) {
  return s.toLowerCase()
    .split(' ')
    .map(word => capitalize(word))
    .join(' ');
}

export function splitInWordsByUpperCases(s) {
  return s.match(/[A-Z][a-z]+/g).join(" ");
}

export function camelCase(s) {
  return s[0].toLowerCase() + s.substring(1)
}

export function isValidEmail(email) {
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function truncate(s, limit) {
  return s.length > limit ? s.substring(0, limit - 3) + "..." : s
}

// Dictionaries

export function compareDictionaries(dictionary, otherDictionary) {
  const keys = Object.keys(dictionary);
  const otherKeys = Object.keys(otherDictionary);

  if (!compareArrays(keys, otherKeys)) {
    return false;
  }

  const values = Object.values(dictionary);
  const otherValues = Object.values(otherDictionary);

  return compareArrays(values, otherValues);
}

// Objects

export function keysToLowerCase(obj) {
  var key, keys = Object.keys(obj);
  var n = keys.length;
  var newobj = {}
  while (n--) {
    key = keys[n];
    newobj[key.toLowerCase()] = obj[key];
  }
  return newobj;
}

// Arrays

export function compareArrays(array, otherArray) {

  if (array.length !== otherArray.length) {
    return false;
  }

  const array1 = array.concat().sort();
  const array2 = otherArray.concat().sort();

  for (var i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i])
      return false;
  }
  return true;
}

export function reorderArray(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export function replaceAll(str, search, replace) {
  while (str.indexOf(search) >= 0) {
    str = str.replace(search, replace);
  }
  return str
};

export function trimLength(text, length) {
  if (text.length <= length) return text;
  return text.substring(0, length) + "...";
};

export function invertColor(hex, bw) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
      ? '#000000'
      : '#FFFFFF';
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
}

export function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

export function groupBy(xs, f) {
  return xs.reduce((rv, x) => {
    (rv[f(x)] = rv[f(x)] || []).push(x);
    return rv;
  }, {});
}


// Url

export function getHomePage() {
  const href = window.location.href
  return href.substring(0, href.indexOf("#"))
}

export function getUrlProtocol(str) {
  try {
    var u = new URL(str)
    return u.protocol.slice(0, -1)
  } catch (e) {
    return ''
  }
}

export function checkUrlProtocol(url) {
  if (!url) return null;
  if (url.startsWith("//")) return `http:${url}`
  let protocol = getUrlProtocol(url)
  return protocol ? url : `http://${url}`
}

export function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function isHtmlTextEmpty(htmlString) {
  // Create a temporary div element
  var tempDiv = document.createElement('div');

  // Set the HTML content of the temporary div
  tempDiv.innerHTML = htmlString;

  // Get the text content of the temporary div
  var textContent = tempDiv.textContent || tempDiv.innerText || '';

  // Trim whitespace and check if the text content is empty
  return textContent.trim() === '';
}

// Function to perform case-insensitive search
export function findCaseInsensitive(array, searchValue) {
  // Convert both the array elements and the search value to lowercase for case-insensitive comparison
  return array.find(item => item && item.toLowerCase() === searchValue.toLowerCase());
}

// Function to perform case-insensitive search on a specific property of objects
export function findObjectCaseInsensitive(array, propertyName, searchValue) {
  const item = array.find(item => {
    const propertyValue = item ? item[propertyName] : undefined;
    return propertyValue && propertyValue.toLowerCase() === searchValue.toLowerCase();
  });
  return item ? item : {};
}