import {
  ERROR_PACKAGE_NOT_ENABLED, ERROR_OK
}
from '../actionTypes'

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ERROR_PACKAGE_NOT_ENABLED:
      return {
        ...state,
        ...action.payload
      }
    case ERROR_OK:
      return initialState
    default:
      return state
  }
}
