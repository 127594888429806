import serverAPI from '../serverAPI';
import moment from 'moment-timezone';

class TimeZoneHelper {
  constructor() {
    this.timeZone = null;
  }

  loadTimeZone(domain, forceReload) {
    const params = domain ? { domain: domain, d: new Date().getTime() } : null
    if (this.timeZone && !forceReload) {
      return new Promise(r => r(this.timeZone))
    }
    return serverAPI("getianatimezone", "get", params)
      .then(res => {
        return res.results[0]
      })
      .then(tz => {
        this.timeZone = tz
        return this.timeZone
      })
  }

  getTimeZone() {
    return this.timeZone;
  }

  clearTimeZone() {
    this.timeZone = null;
  }

  getMomentFromUTCString(utcString) {
    if (!utcString) return null
    if (!this.timeZone) throw new Error("No time zone has been set")
    return moment.tz(utcString, this.timeZone)
  }

  getDateFromUTCString(utcString, format) {
    if (!utcString) return null
    if (!format) format = "YYYY-MM-DD h:mma"
    return moment(this.getMomentFromUTCString(utcString).format(format), format).toDate()
  }

  getISOStringFromLocalDate(localDate, format) {
    if (!localDate) return null
    if (!this.timeZone) throw new Error("No time zone has been set")
    if (!format) format = "YYYY-MM-DD h:mma"
    return moment.tz(moment(localDate).format(format), format, this.timeZone).toISOString()
  }

  getISOStringFromLocalDateAsUTC(localDate) {
    if (!localDate) return null
    return moment.tz(moment(localDate).format("YYYY-MM-DD h:mma"), "YYYY-MM-DD h:mma", "UTC").toISOString()
  }

  getLocalMoment() {
    if (!this.timeZone) throw new Error("No time zone has been set")
    return moment.tz(moment(), this.timeZone)
  }

  getLocalDate() {
    return this.getLocalMoment().toDate()
  }

  getLocalTimeZoneAbbr() {
    const guessedZone = moment.tz.guess()
    var zone = moment.tz.zone(guessedZone)
    if (zone && zone.abbrs?.length > 0) {
      return zone.abbrs[0]
    }
    return ""
  }

  /** 
 * Determines the ordinal suffix for a given date, making it more human-readable, 
 * such as "st" for 1st, "nd" for 2nd, "rd" for 3rd, and "th" for all other numbers 
 * @param day The day of the month 
 */
  getOrdinalNumberSuffix(day) {
    if (day > 3 && day < 21) return "th";

    const remainder = day % 10;
    if (remainder === 1) return "st";
    if (remainder === 2) return "nd";
    if (remainder === 3) return "rd";

    return "th";

  }

  /**
   * Formats a DateTime type retrieved from the server to a string: Day of week, Month, Day of Month
   * @param {DateTime} eventDate The start or end time of an event
   */
  formatDate(eventDate) {
    const date = new Date(eventDate);
    const suffix = this.getOrdinalNumberSuffix(date.getDate())
    const strDate = date.toLocaleDateString("en-US", { weekday: 'long', month: 'long', day: 'numeric' }) + suffix;

    return strDate;
  }

/**
 * Generates a string for displaying a time range in 1) the conference time zone 
 * and 2) the client's local timezone
 * @param {DateTime} scheduledStartTime A moment object representing the start time in the conference's time zone
 * @param {DateTime} scheduledEndTime A moment object representing the end time in the conference's time zone
 */
  getTimeRangeString(scheduledStartTime, scheduledEndTime) {
 
    // Format the time range in the conference timezone
    const formattedTimeConf = scheduledStartTime.format('h:mm A') + ' - ' + scheduledEndTime.format('h:mm A') + ` (${scheduledStartTime.format('z')})`;
  
    // Convert the moment objects to the local timezone and format them
    const localStartTime = scheduledStartTime.clone().tz(moment.tz.guess());
    const localEndTime = scheduledEndTime.clone().tz(moment.tz.guess());
  
    // Check if the conference and local timezones are the same
    const isSameZone = scheduledStartTime.tz() === localStartTime.tz(); 

    // If the conference and local timezones are the same, return the time range
    if (isSameZone) {
      return `${formattedTimeConf}`;
    } 
    // Otherwise, return two timeranges: one for the conference timezone, and one for the local timezone
    else {
      const formattedLocalTime = ` / ${localStartTime.format('h:mm A')} - ${localEndTime.format('h:mm A')} (your time)`;
      return `${formattedTimeConf} ${formattedLocalTime}`;
    }
  }

}

const timeZoneHelper = new TimeZoneHelper()
export default timeZoneHelper
