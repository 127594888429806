import React, { Component } from 'react';
import { Button } from 'reactstrap';
import Alert from 'react-s-alert';

import { flag } from '../../helpers/CompanyHelper';
import { confirm } from '../ConfirmationModal';

class More extends Component {

  reportCompany = () => {
    const attributes = {
      color: 'danger',
      onYes: () => {
        flag(this.props.company.Id)
          .then(() => {
            Alert.success("Company profile has been reported as inappropriate")
          })
          .catch(e => {
            Alert.error(e.message)
          });
      }
    }
    confirm(`Report ${this.props.company.Name} profile as inappropriate?`, attributes);
  }

  render() {
    return (
      <Button size="sm" color="danger" outline block onClick={this.reportCompany}>
        <i className="far fa-flag" /> Report as Inappropriate
      </Button>
    )
  }
}

export default More