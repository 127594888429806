import React, { Component } from 'react';

import { Button } from 'reactstrap';

class ImageButton extends Component {

  render() {

    var disabled = this.props.processing != null && this.props.processing !== ""
    if (this.props.hasOwnProperty("disabled"))
      disabled = this.props.disabled

    return (
      <div>
        <Button
          type={this.props.type}
          size={this.props.size}
          color={this.props.color}
          disabled={disabled}
          outline={this.props.outline}
          onClick={this.props.onClick}
          className={`${this.props.icon ? "btn-brand" : ""} d-none d-${this.props.breakpoint}-inline ${this.props.className}`}>
          {this.props.icon &&
            <i className={this.props.icon}></i>
          }
          <span>{this.props.processing ? this.props.processing : this.props.children}</span>
        </Button>
        <Button
          type={this.props.type}
          size={this.props.size}
          color={this.props.color}
          block={this.props.block}
          disabled={disabled}
          outline={this.props.outline}
          onClick={this.props.onClick}
          className={"d-inline d-" + this.props.breakpoint + "-none " + this.props.className}>
          {this.props.icon &&
            <><i className={this.props.icon} /> &nbsp;&nbsp;</>
          }
          {this.props.processing ? this.props.processing : this.props.children}
        </Button>
      </div>
    );
  }
}

ImageButton.defaultProps = {
  className: "",
  breakpoint: "sm",
  block: true
}

export default ImageButton;
