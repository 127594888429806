import React from 'react'

import CalendarIcon from '../../components/CalendarIcon';
import ListRowTitle from '../Controls/List/ListRowTitle'
import ListRowSubtitle from '../Controls/List/ListRowSubtitle'

import { Row, Col } from 'reactstrap'

import TimeZoneHelper from '../../helpers/TimeZoneHelper';

import { getFromToString } from '../../helpers/WorkshopHelper';

import classnames from 'classnames'

import BrowserLink from '../Browser/BrowserLink'


export default function WorkshopRow(props) {
  const startDate = TimeZoneHelper.getMomentFromUTCString(props.workshop.StartTime)
  return (
    <Row className={classnames("no-gutters", props.className)}>
      <Col className="col-auto mr-3 pt-1 text-center">
        <CalendarIcon moment={startDate} style={{width: "40px"}} />
      </Col>
      <Col>
        <ListRowTitle>
          <BrowserLink id={props.workshop.Id} type="session" fallbackUrl={`/sessions/view?id=${props.workshop.Id}`}>
            {props.workshop.Name}
          </BrowserLink>
        </ListRowTitle>
        <ListRowSubtitle>
          {getFromToString(props.workshop)} at {props.workshop.Room.Name}
        </ListRowSubtitle>
      </Col>
    </Row>
  )
}