import React, { Component } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';

class Booth extends Component {

  render() {
    return (
      <Card>
        <CardHeader className="bg-primary text-center">
          Booth Information
        </CardHeader>
        <CardBody>
          {this.renderBooth(this.props.company.Booth)}
        </CardBody>
      </Card>
    )
  }

  renderBooth = (booth) => {
    if (!booth) {
      return <div className="text-secondary">No information available</div>
    }
    return booth
  }
}

export default Booth