import React, { Component } from 'react';

import { ButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import loading from '../../../assets/img/loading.gif';
import { isPermissionGranted } from '../../../helpers/NavigationHelper';

class ListRowMenu extends Component {
  constructor(props){
    super(props);
    this.state = {
      open: false
    }
  }
  
  toggle = (e) => {
    e.stopPropagation();
    this.setState(state => ({ 
      open: !state.open 
    }));
  }

  render(){
    if (this.props.loading) return (
      <img src={loading} style={{width:36, height: 36}} alt="" />
    );

    const items = React.Children.toArray(this.props.children).filter(item => isPermissionGranted(item.props.permission))    
    if (items.length === 0) return null;

    return (
      <ButtonDropdown isOpen={this.state.open} toggle={this.toggle}>
        <DropdownToggle color="black">
          <i className={this.props.icon}></i>
        </DropdownToggle>
        <DropdownMenu right>
          {items}
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}

ListRowMenu.defaultProps = {
  icon: "fas fa-ellipsis-h"
}

export default ListRowMenu;