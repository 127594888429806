import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

class ListRowTitle extends Component {

  onClickLink = (e) => {
    e.preventDefault();
    this.props.onClick()
  }

  render() {
    if (this.props.link) {
      return (
        <a href={"/#" + this.props.link}>
          {this.props.children}
        </a>
      )
    }

    if (this.props.onClick) {
      return (
        <a href="/#" onClick={this.onClickLink}>
          {this.props.children}
        </a>
      )
    }

    return (
      <div>
        {this.props.children}
      </div>
    )
  }
}

export default withRouter(ListRowTitle);
