import serverAPI from '../serverAPI';
import securityHelper from './SecurityHelper';
import TimeZoneHelper from './TimeZoneHelper';
import moment from 'moment';

/* Component for retrieving the publication status of the Schedule at a Glance. Used to determine its visibility on the navigation bar */
class ScheduleHelper {
    constructor() {
        this.publicationStatus = null;
    }

    /* Loads the schedule publication status from the server */
    async loadPublicationStatus() {
        const user = securityHelper.getUser()
        if (!user) {
            return;
        }

        await serverAPI("schedule.getpublicationstatusaction", "get")
            .then(r => {
                this.publicationStatus = r.results[0];
            })
            .catch(error => {
                console.error("Failed to retrieve the publication status: " + error.message)
            })
    }
    /**
     * Sorts events by date, start time, and description in the conference's time zone. 
     * Adjusts 'StartTime' and 'EndTime' to the conference time zone before sorting.
     * Returns a new array without modifying the original.
     *
     * @param {Object[]} events - Array of event objects with 'Date', 'StartTime', 'EndTime', and 'Description'.
     * @returns {Object[]} Sorted array of events with time adjusted to conference time zone.
     */
    sortEventsByConferenceTimeZone(events) {
        var eventsClone = [...events];
        // Adjust event times to the conference time zone
        for (var i = 0; i < eventsClone.length; i++) {
            eventsClone[i] = {
                ...eventsClone[i],
                StartTime: moment.utc(eventsClone[i].StartTime).tz(TimeZoneHelper.getTimeZone()),
                EndTime: moment.utc(eventsClone[i].EndTime).tz(TimeZoneHelper.getTimeZone()),
            };
        }

        // Sort events by date, then start time, then description
        eventsClone.sort((a, b) => {
            // Compare by eventDate
            let dateComparison = moment(a.Date).diff(moment(b.Date));
            if (dateComparison !== 0) return dateComparison;

            // If eventDate is the same, compare by StartTime
            let startTimeComparison = moment(a.StartTime).diff(moment(b.StartTime));
            if (startTimeComparison !== 0) return startTimeComparison;

            // If StartTime is the same, compare by description
            return a.Description.localeCompare(b.Description);
        });

        return eventsClone;
    }
}

const scheduleHelper = new ScheduleHelper()
export default scheduleHelper
