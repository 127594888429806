import React, { Component } from 'react';

import {
  Card, CardBody, CardHeader, Col,
  Form, FormGroup, Input, Label, Row
} from 'reactstrap';

import PageTitle from '../../../../components/PageTitle';
import Loading from '../../../../components/Loading';

import FooterActionBar from '../../../../components/FooterActionBar';
import FinishButton from '../../../../components/Controls/Buttons/FinishButton';
import CancelButton from '../../../../components/Controls/Buttons/CancelButton';
import DateTimePicker from '../../../../components/DateTimePicker';
import EntitySearch from '../../../../components/EntitySearch';
import RequiredMark from '../../../../components/RequiredMark';
import FieldSet from '../../../../components/Controls/FieldSet';

import TimeZoneHelper from '../../../../helpers/TimeZoneHelper';

import Alert from 'react-s-alert';

import queryString from 'query-string';

import serverAPI from '../../../../serverAPI';
import { withRouter } from 'react-router';


class Edit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      saving: false
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({ saving: true })
    this.saveAppointmentData()
  }

  loadAppointmentData = () => {
    if (!this.id) {
      let state = {
        loading: false,
        guest: this.guestId ? { Id: this.guestId, Title: this.guestName } : null
      }
      this.setState(state)
      return
    }

    serverAPI("appointment.get", "get", { id: this.id })
      .then(res => res.results[0])
      .then(appointment => this.setAppointmentData(appointment))
      .catch(e => {
        Alert.error(e.message)
        this.props.history.goBack()
      })
  }

  setAppointmentData = appointment => {
    this.setState({
      loading: false,
      id: this.id,
      date: TimeZoneHelper.getDateFromUTCString(appointment.Date),
      guest: appointment.Guest ? { Id: appointment.Guest.Id, Title: appointment.Guest.FullName } : null,
      location: appointment.Location,
      message: appointment.Message
    })
  }

  saveAppointmentData = () => {
    var appointment = {
      id: this.id,
      date: TimeZoneHelper.getISOStringFromLocalDate(this.state.date, "YYYY-MM-DD h:mm:ssa"),
      guest: this.state.guest?.Id,
      companyid: this.props.company?.Id,
      location: this.state.location,
      message: this.state.message
    }

    serverAPI("appointment.save", "post", appointment)
      .then(() => {
        Alert.success("Appointment saved successfully!")
        if (this.props.onFinish) {
          this.props.onFinish()
        } else {
          this.props.history.push(`/appointments?_d=${new Date().getTime()}`)
        }
      })
      .catch(e => {
        this.setState({ saving: false })
        Alert.error(e.message)
      })
  }

  componentDidUpdate() {
    if (this.props.company) return

    let params = queryString.parse(this.props.location.search)
    if (this.id === params.id) return
    this.id = params.id
    this.setState({ loading: true }, this.loadAppointmentData)
  }

  componentDidMount() {
    if (this.props.company) {
      this.setState({ loading: false })
      return
    }
    let params = queryString.parse(this.props.location.search)
    this.id = params.id
    this.guestId = params.uid
    this.guestName = params.name
    this.loadAppointmentData()
  }

  getPageTitle() {
    if (this.id) return "Reschedule Appointment";
    return "Schedule Appointment";
  }

  onCancel = () => {
    if (this.props.onFinish) {
      this.props.onFinish()
    } else {
      this.props.history.push("/appointments")
    }
  }

  render() {
    if (this.state.loading) return <Loading />

    return (
      <div className="animated fadeIn">
        {!this.props.embed &&
          <PageTitle text={this.getPageTitle()} />
        }
        <Form className="form-horizontal" onSubmit={this.handleSubmit}>
          <FieldSet permission="appointment.save">
            <Row>
              <Col xs="12" md="12">
                <Card>
                  <CardHeader>
                    <i className="fa fa-info"></i>Appointment Information
                  </CardHeader>
                  <CardBody>
                    <FormGroup row>
                      <Col md="4">
                        <Label for="name">{this.props.company ? "Company" : "Person"} <RequiredMark /></Label>
                      </Col>
                      <Col xs="12" md="8">
                        {
                          this.state.id || this.guestId || this.props.company ?
                            <Input
                              type="text"
                              value={this.state.guest?.Title || this.props.company.Name}
                              disabled
                            />
                            :
                            <EntitySearch
                              id="esUser"
                              searchAction="user.search"
                              selected={this.state.guest ? [{ ...this.state.guest }] : []}
                              onChange={selected => {
                                this.setState({ guest: selected.length ? { ...selected[0] } : null })
                              }}
                              inputProps={{ id: "esUser", required: true }}
                              placeholder="Search the person you're meeting with..."
                              showPhoto
                            />
                        }
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md="4">
                        <Label for="date">Date <RequiredMark /></Label>
                      </Col>
                      <Col xs="12" md="8">
                        <DateTimePicker
                          value={this.state.date}
                          onChange={d => this.setState({ date: d })}
                          dateFormat="MM/dd/yyyy h:mm:ss a"
                          momentFormat="MM/DD/YYYY h:mm:ss a"
                          placeholderText="When the appointment takes place..."
                          required
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md="4">
                        <Label for="location">Location</Label>
                      </Col>
                      <Col xs="12" md="8">
                        <Input
                          id="location"
                          type="text"
                          placeholder="Where the appointment takes place..."
                          value={this.state.location}
                          onChange={e => this.setState({ location: e.target.value })}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md="4">
                        <Label for="information">Information</Label>
                      </Col>
                      <Col xs="12" md="8">
                        <Input
                          id="information"
                          type="text"
                          placeholder="Additional information for this appointment..."
                          value={this.state.message}
                          onChange={e => this.setState({ message: e.target.value })}
                        />
                      </Col>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <FooterActionBar>
              <FinishButton type="submit" finishing={this.state.saving} />
              <CancelButton type="reset" onCancel={this.onCancel} />
            </FooterActionBar>
          </FieldSet>
        </Form>
      </div>
    )
  }
}

export default withRouter(Edit);
