import React, { Component } from 'react';

import { Card, CardBody, Row, Col } from 'reactstrap';

import PageTitle from '../../../components/PageTitle';
import PresentationLinkWidget from '../../../components/Controls/Widgets/PresentationLinkWidget';

class SwipeQRLanding extends Component {

  render() {
    return (
      <div className="animated fadeIn">
        <PageTitle text="Session QR" />
        <Card>
          <CardBody className="pb-0">
            <Row>
                <Col xs="12" sm="6" md="6">
                  <PresentationLinkWidget
                    title="Show On-Screen"
                    subtitle="Show session qr codes on-screen"
                    icon="fas fa-desktop"
                    linkText="Show"
                    onLinkClick={() => this.props.history.push("/swipes/swipeqr")} />
                </Col>
                <Col xs="12" sm="6" md="6">
                  <PresentationLinkWidget
                    title="Export to PDF"
                    subtitle="Export session QRs to a PDF file"
                    icon="fas fa-print"
                    linkText="Print"
                    onLinkClick={() => this.props.history.push("/swipes/printqr")} />
                </Col>
              </Row> 
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default SwipeQRLanding;
