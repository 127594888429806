import store from './redux/store'

import { logoutOk } from './redux/actions/session'
import { packageNotEnabled, noError } from './redux/actions/error'

import { keysToLowerCase } from './general'

import SecurityHelper from './helpers/SecurityHelper'


class ServerError extends Error {
  constructor(message, flag, data) {
    super(message)
    this.errorFlag = flag;
    this.data = data;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, ServerError);
    } else {
      this.stack = (new Error(message)).stack;
    }
  }
}

function encodeQueryString(params) {
  const keys = Object.keys(params)
  return keys.length
    ? "?" + keys
      .map(key => encodeURIComponent(key)
        + "=" + encodeURIComponent(params[key]))
      .join("&")
    : ""
}

var _captchaToken = null

export function getCaptchaToken() {
  return _captchaToken
}

export default function serverAPI(action, method, params, body) {
  var url = "/Service/";

  if (!method) method = "get";
  const isPost = method.toLowerCase() === "post";

  if (!params) params = {};

  params = keysToLowerCase(params)
  if (_captchaToken) params.captchatoken = _captchaToken

  const adminAccount = SecurityHelper.getAdminAccount()
  if (adminAccount) params.on_account = adminAccount.id

  var urlObject = Object.assign({}, { action: action });
  if (!isPost || body)
    urlObject = Object.assign(urlObject, params);

  url += encodeQueryString(urlObject);

  if (!body && isPost)
    body = JSON.stringify(params)

  return fetch(url, {
    method: method,
    body: body
  })
    .then(res => {
      if (!res || res.status > 200) {
        throw new ServerError("Unable to process your request. Please try again later or contact your administrator.", "unknownerror")
      }
      return res.json()
    })
    .then(json => {
      if (!json || typeof (json.success) === "undefined") {
        throw new ServerError("Unable to process your request. Please try again later or contact your administrator.", "unknownerror")
      }

      _captchaToken = json.captcha && json.captcha.Token ? json.captcha.Token : null

      let showError = true

      if (json.success === false) { 
        if(params.returnresponse)
        {
          return json
        }
        else if (json.error_flag === "sessionexpired") {
          store.dispatch(logoutOk())
        }
        else if (json.error_flag === "packagenotenabled") {
          store.dispatch(packageNotEnabled(json))
          showError = false
        }        
        else {
          store.dispatch(noError())
        }
        throw new ServerError(json.message, json.error_flag, { showError: showError, captchaToken: _captchaToken })
      }

      return json
    })
}

export function serverAPIJoiningResults(action, params, parser) {
  if (!parser) {
    parser = (res) => res.results
  }
  let items = []
  let from = 0
  let size = -1
  const count = 1000
  const data = Object.assign({}, params);
  return new Promise(async (resolve, reject) => {
    while (size >= count || size === -1) {
      data.from = from
      data.count = count
      try {
        const response = await serverAPI(action, "get", data)
        const results = parser(response)
        items.push(...results)
        size = results.length
        from += size
      } catch (e) {
        reject(e)
      }
    }
    resolve(items)
  })
}

export function sendBeacon(action, params, body){
  var urlObject = Object.assign({}, { action: action }, params);
  const url = "/Service/" + encodeQueryString(urlObject);

  navigator.sendBeacon(url, JSON.stringify(body || {}))
}
