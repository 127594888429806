import React from 'react'

export default class SocialNetworks extends React.Component {
  render() {
    return (
      <>
        {
          this.props.facebook &&
          <div className="mb-2">
            <i className={`fab fa-facebook ${this.props.iconClassName}`}></i> <a target="_blank" rel="noopener noreferrer" href={this.props.facebookUrl}>{this.props.facebook}</a>
          </div>
        }
        {
          this.props.linkedin &&
          <div className="mb-2">
            <i className={`fab fa-linkedin ${this.props.iconClassName}`}></i> <a target="_blank" rel="noopener noreferrer" href={this.props.linkedinUrl}>{this.props.linkedin}</a>
          </div>
        }
        {
          this.props.youtube &&
          <div className="mb-2">
            <i className={`fab fa-youtube ${this.props.iconClassName}`}></i> <a target="_blank" rel="noopener noreferrer" href={this.props.youtubeUrl}>{this.props.youtube}</a>
          </div>
        }
        {
          this.props.twitter &&
          <div className="mb-2">
            <i className={`fab fa-twitter ${this.props.iconClassName}`}></i> <a target="_blank" rel="noopener noreferrer" href={this.props.twitterUrl}>{this.props.twitter}</a>
          </div>
        }
        {
          this.props.instagram &&
          <div className="mb-2">
            <i className={`fab fa-instagram ${this.props.iconClassName}`}></i> <a target="_blank" rel="noopener noreferrer" href={this.props.instagramUrl}>{this.props.instagram}</a>
          </div>
        }
      </>
    )
  }
}