import { matchPath } from 'react-router-dom';
import $ from 'jquery'
import queryString from 'query-string'

import SecurityHelper from './SecurityHelper';
import PlanHelper from './PlanHelper';

import administrator from '../navigation/administration';
import attendee from '../navigation/attendee';
import exhibitor from '../navigation/exhibitor'; 
import { boothNav } from '../navigation/exhibit-booth';

import routes from '../routes'; 
import * as icon from '../assets/Icons';

export default function navigation() {
  let items = administrator
  if (SecurityHelper.isAttendeeOnly()) {
    items = attendee
  }  
  else if (SecurityHelper.isExhibitorOnly()) {
    items = exhibitor
  }
 
  const visibleChildren = getVisibleChildren(PlanHelper.prepareMenu(items()));

  visibleChildren.push( {
    name: 'About',
    url: '/about',
    icon: icon.info_circle,
    tag: 'about',
    alwaysIcon: true
  }) 
  
  return {items: visibleChildren};
}

export function booth(company){
  let items = boothNav()
  if (company){ 
    items = items.filter(i => {
      // Ensure 'visible' is either undefined, null, or a function
      if (i.visible === undefined || i.visible === null) {
        return true;
      }
      if (typeof i.visible === 'function') {
        return i.visible(company);
      }
      // If 'visible' is neither undefined, null, nor a function, we skip this item
      return false;
    });
  }
  items.forEach(i => i.url = "/exhibitors/virtual/booth" + i.url)
  return {items: items};
}

function getVisibleChildren(items){
  var visibleChildren = [];
  items.forEach(item => {
    if (!isPermissionGranted(item.visible)) return;

    const visibleItem = Object.assign({}, item);

    if (!item.children){
      visibleChildren.push(visibleItem);
      return;
    }

    const children = getVisibleChildren(item.children);
    if (children.length === 0) return;

    visibleItem.children = children;
    visibleChildren.push(visibleItem);
  }) 

  return visibleChildren;
}

export function isPermissionGranted(permission){
  if (permission === undefined){
    return true;
  }
  
  if (typeof permission === 'function') {
    return permission();
  }

  if (typeof permission === 'string') {
    return SecurityHelper.canExecuteAction(permission);
  }

  if (Array.isArray(permission)){
    return SecurityHelper.canExecuteAnyAction(permission);
  }
    
  return permission;
}

export function getPageTitle(path){
  const paths = getPaths(path);
  const items = paths.map((path, i) => findRouteName(path)).filter(name => name);
  return items.join(' > ');
}

function findRouteName(url){
  const aroute = routes.find(route => matchPath(url, {path: route.path, exact: route.exact}));
  return (aroute && aroute.name) ? aroute.name : null
}

function getPaths(pathname){
  const paths = ['/'];

  if (pathname === '/') return paths;

  pathname.split('/').reduce((prev, curr) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });
  paths.splice(0, 1);
  return paths;
}

export function isSideBarVisible() {
  return $('#btnSideBarToggler').is(":visible")
}

export function isSideBarOpen() {
  return parseInt($("aside.aside-menu").css("marginRight")) === 0
}

export function openSideBar() {
  if (isSideBarOpen()) return
  $("#btnSideBarToggler > button").click()
}

export function closeSideBar(){
  if (!isSideBarOpen()) return
  $("#btnSideBarToggler > button").click()
}

export function currentSideBarTitle(){ 
  return $("#aside-bar-title").attr('title')
}

function getUrlForBrowser(location, type, id, tool, forceRefresh) {
  let params = queryString.parse(location.search)
  params._bt = type
  params._bid = id
  if (tool)
    params._btl = tool
  else
    delete params._btl
  if (forceRefresh)
    params._d = new Date().getTime()
  else
    delete params._d
  const newSearch = queryString.stringify(params)

  let separator = "?"
  if (location.pathname.includes("?")){
    separator = "&"
  }
  
  return location.pathname + separator + newSearch
}

export function getBrowsedEntityUrl(location, type, id, fallbackUrl, tool, forceRefresh) {
  return isSideBarVisible() ?
    getUrlForBrowser(location, type, id, tool, forceRefresh) :
    fallbackUrl
}