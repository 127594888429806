import React, { Component } from 'react';
import { render, unmountComponentAtNode } from 'react-dom'

import { Modal, ModalBody, Button } from 'reactstrap';

const types = {
  delete: {
    icon: "far fa-trash-alt",
    color: "danger",
    yes: "Delete",
    no: "Cancel"
  },
  important: {
    icon: "far fa-question-circle",
    color: "danger" 
  },
  default: {
    icon: "far fa-question-circle"
  }
}

export default class ConfirmationModal extends Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: true }
  }

  yes = () => {
    this.props.onYes();
    if(!this.props.doNotHideOnYes)
    {
      this.toggle()
    }
  }

  no = () => {
    this.props.onNo();
    this.toggle();
  }

  extra = () => {
    this.props.onExtra()
    this.toggle()
  }

  toggle = () => {
    this.setState({ isOpen: false });
    setTimeout(() => close(), 500);
  }

  render() {
    const type = types[this.props.type] || types["default"];

    return (
      <Modal isOpen={this.state.isOpen} toggle={this.toggle} className={this.props.extra || this.props.wideBox ? "modal-md" : "modal-sm"}>
        <ModalBody>
          <div className="text-center"><h5><i className={type.icon} /></h5></div>
          <div className={"py-1 text-center"}>
            <div dangerouslySetInnerHTML={{ __html: this.props.text }}></div>
            { this.props.dangerMessage  && (<b className='text-danger'>{this.props.dangerMessage}</b>)}
          </div>
          <div className="mt-3 text-right">
            <Button className="mr-3" color={type.color || this.props.color} onClick={this.yes}>{type.yes || this.props.yes}</Button>
            {this.props.extra &&
              <Button className="mr-3" color="secondary" onClick={this.extra}>{this.props.extra}</Button>
            }
            <Button color={type.noColor || this.props.noColor} onClick={this.no}>{type.no || this.props.no}</Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

ConfirmationModal.defaultProps = {
  type: 'default',
  yes: "Yes",
  no: "No",
  color: "primary",
  noColor: "secondary",
  onYes: () => { },
  onNo: () => { },
  doNotHideOnYes : false
}

function close() {
  const wrapper = document.getElementById("confirmation-modal")
  unmountComponentAtNode(wrapper)
  wrapper.parentNode.removeChild(wrapper)   
}

export function confirm(text, props) {
  var wrapper = document.getElementById("confirmation-modal") 
  if (!wrapper) {
    wrapper = document.createElement('div')
    wrapper.id = "confirmation-modal" 
    document.body.appendChild(wrapper)
  }
  render(<ConfirmationModal text={text} {...props} />, wrapper)
}

export function destroyConfirmation() { 
  close()
}