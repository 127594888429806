import React from 'react'

import { Card, CardBody, Col, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { isPermissionGranted } from '../helpers/NavigationHelper';

const MAX_VISIBLE_ITEMS = 5

class Bar extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      menuExpanded: false
    }
  }

  toggleMenu = () => {
    this.setState(state => ({menuExpanded: !state.menuExpanded}));
  }

  isLastItem = (index) => {
    if (this.isMoreVisible()){
      return false;
    }
    return this.props.items.length - 1 === index;
  }

  isMoreVisible = () => {
    return this.props.items.length > MAX_VISIBLE_ITEMS;
  }

  getVisibleItems = () => {
    const items = this.props.items;
    if (!this.isMoreVisible()){
      return items;
    }
    return items.slice(0, MAX_VISIBLE_ITEMS);
  }

  getMenuItems = () => {
    if (!this.isMoreVisible()){
      return [];
    }
    return this.props.items.slice(MAX_VISIBLE_ITEMS);
  }

  render() {
    const items = this.getVisibleItems();
    if (items.length === 0) return null;

    return (
      <Row>
        <Col sm="12" md="9">
          <Row> 
            {items.map((child, index) =>
              <Col key={index} sm="12" md="auto" className="pb-3">
                {React.cloneElement(child, { size: "sm", block: true, style: {minWidth: 100 } })}
              </Col>
            )}
          </Row>
        </Col>
        {this.isMoreVisible() &&
          <Col sm="12" md="3" className="pb-3">
            <Row className="justify-content-end">
              <Col sm="12" md="auto">
                <Dropdown isOpen={this.state.menuExpanded} style={{minWidth: "120px"}} toggle={this.toggleMenu}>
                  <DropdownToggle block caret outline size="sm" color="primary">
                    More
                  </DropdownToggle>
                  <DropdownMenu right>
                    {this.getMenuItems().map((item, index) =>
                      <DropdownItem key={index} onClick={item.props.onClick}>{item.props.children}</DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        }
      </Row>
    )
  }
}

class HeaderActionBar extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }

  getAllowedItems = () => {
    return React.Children.toArray(this.props.children).filter(item => isPermissionGranted(item.props.permission));
  }

  render() {
    const items = this.getAllowedItems();
    if (items.length === 0) return null;

    const cardStyle = this.props.reduceTopSpaces ? { marginTop: '0.5rem', marginBottom: '0.5rem' } : {};

    return (
        <div>
          {!this.props.embedded &&
            <Card style={cardStyle}>
              <CardBody  className="pb-0">
                <Bar items={items}/>
              </CardBody>
            </Card>
          }
          {this.props.embedded &&
            <div>
              <Bar items={items}/>
            </div>
          }
        </div>
    )
  }
}

HeaderActionBar.defaultProps = {
  embedded: false
}

export default HeaderActionBar;
