import React, { Component, Suspense } from 'react';

import { Route, Switch, withRouter } from 'react-router-dom';
import { Col, Container, Row, Alert as AlertMessage, Card, CardBody, Modal, ModalBody, ModalHeader, Button, Badge } from 'reactstrap';

import {
  AppFooter,
  AppHeader,
  AppAside,
  AppSidebar,
  AppSidebarNav as Sidebar
} from '@coreui/react';

import Alert from 'react-s-alert';
import ReactImageGallery from 'react-image-gallery';
import queryString from 'query-string';
import moment from 'moment';

import TimeZoneHelper from '../../helpers/TimeZoneHelper';
import ConferenceHelper from '../../helpers/ConferenceHelper';
import SecurityHelper from '../../helpers/SecurityHelper';
import PackageHelper from '../../helpers/PackageHelper';
import { booth } from '../../helpers/NavigationHelper';
import { requestInfo } from '../../helpers/CompanyHelper';

import serverAPI, { serverAPIJoiningResults } from '../../serverAPI';

import Media from '../../components/Media';
import { sleep } from '../../general';

import Edit from '../../views/Pages/AttendeeSite/Appointment/Edit';

import Loading from '../../components/Loading';

import company from '../../assets/img/generic-company.png';
import { appointment } from '../../assets/Icons';
import HeaderActionBar from '../../components/HeaderActionBar';

import { routes } from '../../navigation/exhibit-booth';
import Linkify from 'linkifyjs/react';
import scheduleHelper from '../../helpers/ScheduleHelper';

const BoothFooter = React.lazy(() => import('./BoothFooter'));
const BoothHeader = React.lazy(() => import('./BoothHeader'));
const BoothAside = React.lazy(() => import('./BoothAside'));


const ChatFeed = React.lazy(() => import('../../components/Interaction/ChatFeed'));


class BoothLayout extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      current: 0
    }
  }

  componentDidMount() {
    const promises = []

    const conference = ConferenceHelper.getConference()
    if (!conference) {
      promises.push(TimeZoneHelper.loadTimeZone(),
        ConferenceHelper.loadConference(),
        PackageHelper.loadPackages(),
        SecurityHelper.loadUser(),
        )
    }

    Promise.all(promises)
      .then(() => sleep(1000))
      .then(this.loadExhibitors)
      .then( () => { scheduleHelper.loadPublicationStatus() })
      .catch(() => Alert.error("Exhibit Booth is not available"))
  }

  loadExhibitors = () => {
    serverAPIJoiningResults("company.listexhibithall", { socialfullurls: true })
      .then(this.setExhibitors)
  }

  setExhibitors = (exhibitors) => {
    this.groupFiles(exhibitors)

    const params = queryString.parse(this.props.location.search)
    let current = 0
    if (params.id) {
      const index = exhibitors.findIndex(e => e.Id === params.id)
      if (index >= 0) {
        current = index
      }
    }

    this.setState({ exhibitors: exhibitors, loading: false, current: current }, this.updateUrl)
  }

  groupFiles = (exhibitors) => {
    exhibitors.forEach(e => {
      if (e.Media) {
        e.Files.unshift(e.Media)
      }
      e.BoothFiles = e.Files.filter(f => f.Type.match(/^(video|image)$/))
    })
  }

  onPrevious = () => {
    this.pauseVideos()
    const prev = this.state.current > 0 ? this.state.current - 1 : this.state.exhibitors.length - 1
    this.setState({ current: prev }, this.updateUrl)
  }

  onNext = () => {
    this.pauseVideos()
    const next = this.state.current < this.state.exhibitors.length - 1 ? this.state.current + 1 : 0
    this.setState({ current: next }, this.updateUrl)
  }

  updateUrl = () => {
    const exhibitor = this.state.exhibitors[this.state.current]
    const navigation = booth(exhibitor)
    if (navigation.items.length === 0) return
    if (navigation.items[0].url === this.props.location.pathname) return
    this.props.history.push(navigation.items[0].url)
  }

  pauseVideos = () => {
    // Make sure there are no videos running...
    Array.from(document.getElementsByTagName("video")).forEach(v => {
      v.pause()
    });
  }

  onSlide = () => {
    this.pauseVideos()
  }

  requestMoreInfo = () => {
    const exhibitor = this.state.exhibitors[this.state.current]
    requestInfo(exhibitor.Id)
  }

  openMeeting = () => {
    const exhibitor = this.state.exhibitors[this.state.current]
    serverAPI("company.startmeeting", "post", { company: exhibitor.Id })
    if (!this.isBoothWorking()) {
      this.showMeetingHours()
      return
    }
    window.open(exhibitor.ExhibitMeetingUrl, "_blank")
  }

  openChat = () => {
    const exhibitor = this.state.exhibitors[this.state.current]
    serverAPI("company.startchat", "post", { company: exhibitor.Id })
    this.setState({ chat: exhibitor.Rep })
  }

  isBoothWorking = () => {
    const exhibitor = this.state.exhibitors[this.state.current]
    const format = "hh:mm"
    const now = TimeZoneHelper.getLocalMoment()
    let start = moment("00:00", format)
    let end = moment("23:59", format)
    if (exhibitor.ExhibitStartTime) {
      start = moment(exhibitor.ExhibitStartTime.split("T")[1], format)
    }
    if (exhibitor.ExhibitEndTime) {
      end = moment(exhibitor.ExhibitEndTime.split("T")[1], format)
    }

    return start.isSameOrBefore(now) && end.isSameOrAfter(now)
  }

  showMeetingHours = () => {
    const exhibitor = this.state.exhibitors[this.state.current]
    const format = "hh:mm"

    let start = moment("00:00", format)
    let end = moment("23:59", format)

    if (exhibitor.ExhibitStartTime) {
      start = moment(exhibitor.ExhibitStartTime.split("T")[1], format)
    }
    if (exhibitor.ExhibitEndTime) {
      end = moment(exhibitor.ExhibitEndTime.split("T")[1], format)
    }

    Alert.warning(`Sorry, no live reps are available at this time. Live meetings can take place from ${start.format("hh:mma")} to ${end.format("hh:mma")}.`)
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="d-flex vh-100 align-items-center justify-content-center animated fadeIn">
          <Loading width={100} height={100} />
        </div>
      )
    }

    const exhibitor = this.state.exhibitors[this.state.current]

    return (
      <div className="app animated fadeIn">
        <Modal isOpen={this.state.appointment} size="lg" toggle={() => this.setState(state => ({ appointment: !state.appointment }))}>
          <ModalHeader>Schedule Appointment</ModalHeader>
          {this.state.appointment &&
            <ModalBody>
              <Edit company={exhibitor} embed onFinish={() => this.setState({ appointment: false })} />
            </ModalBody>
          }
        </Modal>
        <Modal isOpen={this.state.chat} size="lg">
          {this.state.chat &&
            <>
              <ModalHeader toggle={() => this.setState({ chat: null })}>Chat with {this.state.chat.FullName}</ModalHeader>
              <ModalBody>
                <Suspense fallback={<Loading />}>
                  <ChatFeed
                    userId={this.state.chat.Id}
                    userFullName={this.state.chat.FullName}
                  />
                </Suspense>
              </ModalBody>
            </>
          }
        </Modal>
        <AppHeader fixed>
          <Suspense fallback={<Loading />}>
            <BoothHeader />
          </Suspense>
        </AppHeader>
        <div className="app-body">
          <main className="main ml-0" style={{ zIndex: 0 }}>
            <Row className="h-100">
              <Col className="pr-0" style={{ marginBottom: 150 }}>
                <Container fluid>
                  <Suspense fallback={<Loading />}>
                    {this.renderBooth()}
                  </Suspense>
                </Container>
              </Col>
              <Col xs="auto" className="pl-0">
                <div style={{ width: 200 }} className="h-100 d-none d-lg-block">
                  <AppSidebar display="md" className="h-100">
                    <Suspense>
                      <Switch>
                        <Route
                          path="/"
                          name="Virtual Booth"
                          render={props => <Sidebar navConfig={booth(exhibitor)} {...props} />}
                        />
                      </Switch>
                    </Suspense>
                  </AppSidebar>
                </div>
              </Col>
            </Row>
          </main>
          <AppAside fixed>
            <BoothAside company={exhibitor} />
          </AppAside>
        </div>
        <AppFooter className="ml-0" style={{ zIndex: 1000, position: 'fixed', bottom: 0, left: 0, right: 0 }}>
          <Suspense fallback={<Loading />}>
            <BoothFooter
              onPrevious={this.onPrevious}
              onNext={this.onNext} />
          </Suspense>
        </AppFooter>
      </div>
    );
  }

  renderBoothBody = exhibitor => {
    if (exhibitor.BoothFiles.length > 0) {
      return (
        <ReactImageGallery
          items={exhibitor.BoothFiles}
          showIndex={true}
          infinite={true}
          renderItem={this.renderFile}
          showThumbnails={false}
          showPlayButton={false}
          showFullscreenButton={false}
          slideInterval={5000}
          onSlide={this.onSlide}
        />
      )
    }

    return (
      <Linkify>{this.renderDescription(exhibitor)}</Linkify>
    )
  }

  renderBooth = () => {
    if (this.state.exhibitors.length === 0) {
      return (
        <AlertMessage color="warning">
          There are no exhibitors at the virtual hall
        </AlertMessage>
      )
    }

    const exhibitor = this.state.exhibitors[this.state.current]

    return (
      <>
        <div className="text-center">
          <img src={exhibitor.Image || company}
            className="w-100 my-3"
            style={{ maxHeight: 80, objectFit: 'contain' }}
            alt={exhibitor.Name}
            onError={e => {
              e.target.onError = null
              e.target.src = company
            }} />
          <Card>
            <CardBody className="text-center">
              <h4 className="mb-0">{exhibitor.Name}</h4>
              {
                exhibitor.TagLine && <h6>{exhibitor.TagLine}</h6>
              }
              <hr />
              {this.renderBoothBody(exhibitor)}
            </CardBody>
          </Card>
          {exhibitor.Rep && this.renderActions(exhibitor)}
        </div>
        <div className="d-lg-none d-block">
          <Card>
            <CardBody>
              <Card className="text-center mt-3">
                <CardBody className="p-2">
                  <h5 className="align-items-center d-inline-flex m-1 text-uppercase">
                    {exhibitor.Sponsorship ?
                      <><Badge className="mr-1" style={{ backgroundColor: exhibitor.Sponsorship.Color }}>&nbsp;&nbsp;</Badge> {exhibitor.Sponsorship.Name} sponsor</>
                      :
                      "Exhibitor"
                    }
                  </h5>
                </CardBody>
              </Card>
              <HeaderActionBar embedded>
                {booth(exhibitor).items.map((item, i) =>
                  <Button key={i} onClick={() => this.props.history.push(item.url)}>{item.name}</Button>
                )}
              </HeaderActionBar>
              <Switch>
                {routes.map((route, idx) =>
                  <Route
                    key={idx}
                    path={"/exhibitors/virtual/booth" + route.path}
                    name={route.name}
                    render={props => <route.component {...props} company={exhibitor} />}
                  />
                )}
              </Switch>
            </CardBody>
          </Card>
        </div>
      </>
    )
  }

  renderActions = (exhibitor) => {
    const md = exhibitor.ExhibitMeetingUrl ? 3 : 4
    return (
      <Row>
        <Col xs="12" md={md} className="mb-3">
          <Button type="button" block color="primary" onClick={this.requestMoreInfo}>
            <i className="fas fa-info-circle"></i> Get More Info
          </Button>
        </Col>
        <Col xs="12" md={md} className="mb-3">
          <Button type="button" block color="success" onClick={() => this.setState({ appointment: true })}>
            <i className={appointment}></i> Schedule Appointment
          </Button>
        </Col>
        {exhibitor.ExhibitMeetingUrl &&
          <Col xs="12" md={md} className="mb-3">
            <Button type="button" block color="warning text-white" onClick={this.openMeeting}>
              <i className="fas fa-phone"></i> Talk to a Live Rep
            </Button>
          </Col>
        }
        <Col xs="12" md={md} className="mb-3">
          <Button type="button" block color="danger" onClick={this.openChat}>
            <i className="far fa-comment"></i> Chat to a Live Rep
          </Button>
        </Col>
      </Row>
    )
  }

  renderFile = (media) => {
    return (
      <div style={{ height: 300 }}>
        <Media media={media} listMode maxHeight={300} key={media.Id} autoPlay />
      </div>
    )
  }

  renderDescription = (exhibitor) => {
    const description = exhibitor.Description
    if (description) {
      return description
    }

    const shortDescription = exhibitor.ShortDescription
    if (shortDescription) {
      return shortDescription
    }
    return <div className="text-secondary">No information available</div>
  }
}

export default withRouter(BoothLayout)