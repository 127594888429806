import React, { Component } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { hasContactInformation } from '../../helpers/CompanyHelper';
import SocialNetworks from '../SocialNetworks';

class Company extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Card>
        <CardHeader className="bg-primary text-center">
          Contact
        </CardHeader>
        <CardBody>
          {this.renderContactInfo()}
        </CardBody>
      </Card>
    )
  }

  renderContactInfo = () => {
    const { company } = this.props

    if (!hasContactInformation(company))
      return <div className="text-secondary">No information available</div>

    return (
      <>
        {company.Website &&
          <div className="mb-2">Website: <a href={company.Website} target="_blank" rel="noopener noreferrer">{company.Website}</a></div>
        }
        {company.Email &&
          <div className="mb-2">Email: <a href={`mailto:${company.Email}`}>{company.Email}</a></div>
        }
        {company.PhoneNumber &&
          <div className="mb-2">Phone Number: <a href={`tel ${company.PhoneNumber}`}>{company.PhoneNumber}</a></div>
        }
        {company.StreetAddress &&
          <div className="mb-2">Address: {company.StreetAddress}, {company.City}, {company.State}, {company.Zip}</div>
        }
        {this.renderSocialProfiles(company)}
      </>
    )
  }

  renderSocialProfiles = (company) => {
    return <SocialNetworks
      iconClassName="text-muted"
      facebook={company.Facebook}
      facebookUrl={company.FacebookUrl}
      linkedin={company.LinkedIn}
      linkedinUrl={company.LinkedInUrl}
      youtube={company.YouTube}
      youtubeUrl={company.YouTubeUrl}
      twitter={company.Twitter}
      twitterUrl={company.TwitterUrl}
      instagram={company.Instagram}
      instagramUrl={company.InstagramUrl} />
  }
}

export default Company