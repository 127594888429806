import React, { Component } from 'react';

import loading from '../assets/img/loading.gif';

class MediaThumbnail extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onRemove = (e) => {
    e.preventDefault()
    this.props.onRemove()
  }

  render() {
    const { file } = this.props

    return (
      <a key={file.Source} href={file.Source} target="_blank" rel="noopener noreferrer" className={this.props.className}
        onMouseOver={() => this.setState({ remove: true })}
        onMouseLeave={() => this.setState({ remove: false })}>
        <div className="usermedia mb-3">
          {
            this.props.onRemove && this.state.remove &&
            <a href="/#" className="usermedia-remove h5" onClick={this.onRemove}>
              <i className="fas fa-minus-circle" />
            </a>
          }
          {
            file.IsPending &&
            <div className="d-flex w-100 h-100 align-items-center" style={{ paddingBottom: 20 }}>
              <div className="text-center w-100">
                <img src={loading} style={{ width: 36, height: 36 }} alt="" />
              </div>
            </div>
          }
          {
            !file.IsPending && file.Type.match(/^(image|video)$/) &&
            this.renderThumbnail(file)
          }
          {
            !file.IsPending && !file.Type.match(/^(image|video)$/) &&
            this.renderFile(file)
          }
          <div className="usermedia-tag text-truncate p-1">{file.Original}</div>
        </div>
      </a>
    )
  }

  renderThumbnail = (file) => {
    return (
      <div>
        <img
          src={file.Thumbnail}
          alt={file.Original}
          className="usermedia-thumbnail"
          onError={e => {
            e.target.onError = null
            e.target.src = "/Images/image.png"
          }}
        />
        {file.Type === 'video' && <i className="usermedia-video far fa-play" />}
      </div>
    )
  }

  renderFile = (file) => {
    const extension = file.Source.split(/#|\?/)[0].split('.').pop().trim();
    var icon;
    try {
      icon = require(`../assets/img/filetypes/${extension}.png`);
    } catch (err) {
      icon = require('../assets/img/filetypes/file.png');
    }
    return <img src={icon} alt={file.Original} className="usermedia-file" />
  }
}

MediaThumbnail.defaultProps = {
  className: ""
}

export default MediaThumbnail