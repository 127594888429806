import React, { Component } from 'react';

import ImageButton from './ImageButton';

class CancelButton extends Component {

  render(){
    return (
      <ImageButton
        type={this.props.type}
        onClick={this.props.onCancel}
        processing={this.props.canceling ? "Canceling..." : null}
        className={this.props.className}
        color="secondary"
        size={this.props.size}
        icon={this.props.icon}>
        {this.props.children}
      </ImageButton>
    );
  }
}

CancelButton.defaultProps = {
  children: "Cancel",
  icon: "fa fa-ban",
  className: ""
}

export default CancelButton;
