import serverAPI from "../serverAPI"
import ConferenceHelper from "./ConferenceHelper"

export const PRE_CONFERENCE_PACKAGE = "C11705D9-87C9-46E7-9BB9-DBE1CD0521B6"
export const ATTENDEE_EXPERIENCE_PACKAGE = "84C00916-D557-432C-B900-137115996390"
export const EXHIBITOR_PACKAGE = "739B0B37-C17D-42B5-BBC9-DAFD28C4E665"
export const VIRTUAL_PACKAGE = "97067AF5-C501-4078-B60A-3CD4716F72DC"
export const BRANDING_PACKAGE = "7B75131E-7900-4BC6-904A-8560692E1264"

export const LEADS_LICENSES_BASIC_PACKAGE = "C4C4ECC0-19FD-41F5-B230-0DB6CD851C86"
export const LEADS_LICENSES_PACKAGE = "B9520CF1-03D1-47FA-9E5D-C96740E3C1EB"  

export const REGSTRATION_PACKAGE = "B608D2AB-2836-420B-82FF-C7DE9BC7A265"   

export const PACKAGE_CHECK_METHOD_ALL = "ALL"
export const PACKAGE_CHECK_METHOD_ONE_OF_ALL = "ONE_OF_ALL"
export const PACKAGE_CHECK_METHOD_FIRST_AND_ONE_OF_REST = "FIRST_AND_ONE_OF_REST"

class PackageHelper {

  loadPackages(){
    return serverAPI("licensepackage.list")
      .then(res => {
        this.packages = [...res.results]
      })
  }

  all(){
    const conference = ConferenceHelper.getConference()
    return [...conference.LicensePackages]
  }

  getByIds(ids){
    return ids.map(id => this.packages.find(p => p.Id.toLowerCase() === id.toLowerCase())).filter(p => p !== undefined)
  }

  isEnabled(id){
    const conference = ConferenceHelper.getConference()
    return this.isEnabledForConference(id, conference)
  }

  isEnabledForConference(id, conference){
    if (!conference || !conference.LicensePackages) return false
    return conference.LicensePackages.some(p => p.toLowerCase() === id.toLowerCase())
  }

  areEnabled(ids){
    return ids.every(id => this.isEnabled(id))
  }

  isPreConferenceEnabled(){
    return this.isEnabled(PRE_CONFERENCE_PACKAGE)
  }

  isAttendeeEnabled(){
    return this.isEnabled(ATTENDEE_EXPERIENCE_PACKAGE)
  }

  isExhibitorEnabled(){
    return this.isEnabled(EXHIBITOR_PACKAGE)
  }

  isVirtualEnabled(){
    return this.isEnabled(VIRTUAL_PACKAGE)
  }

  isBrandingEnabled(){
    return this.isEnabled(BRANDING_PACKAGE)
  }

  isLeadsLicensesPackageEnabled(){
    return this.isEnabled(LEADS_LICENSES_BASIC_PACKAGE)  || this.isEnabled(LEADS_LICENSES_PACKAGE)
  }

  isRegstrationPackageEnabled(){
    return this.isEnabled(REGSTRATION_PACKAGE)
  }


}

const packageHelper = new PackageHelper()
export default packageHelper