import serverAPI from '../serverAPI';

import TimeZoneHelper from './TimeZoneHelper'
import SecurityHelper from './SecurityHelper'

import moment from 'moment'

export function deleteWorkshop(id) {
  return serverAPI("workshop.delete", "post", {id: id});
}

export function canWatchStreaming(workshop) {
  const isAdministrator = SecurityHelper.isAdministrator()
  if (isAdministrator) return true
  
  const isRegistered = SecurityHelper.isAttendee() && workshop.IsRegistered
  if (isRegistered) return true
  
  const isPresenter = workshop.Presenters.some(p => p.Id === SecurityHelper.getUser().Id)
  return isPresenter
}

export function showsWatchStreamingButton(workshop, windowStartMins) {
  const startTime = TimeZoneHelper.getMomentFromUTCString(workshop.StartTime).add(-windowStartMins, "minutes")
  const now = TimeZoneHelper.getLocalMoment()
  const isActive = now.isSameOrAfter(startTime)
  return workshop.AttendanceMode > 0 &&
    workshop.IsStreamingConfigured &&
    isActive &&
    canWatchStreaming(workshop)
}

export function getFromToString(workshop) {
  const localStartTime = moment(workshop.StartTime)
  const localEndtime = moment(workshop.EndTime)
  const startTime = TimeZoneHelper.getMomentFromUTCString(workshop.StartTime)
  const endTime = TimeZoneHelper.getMomentFromUTCString(workshop.EndTime)
  const isSameZone = startTime.format("z") === localStartTime.format("z")
  if (moment(startTime).startOf('day').isSame(moment(endTime).startOf('day'))) {
    if (isSameZone) {
      return startTime.format("LLLL") + " to " + endTime.format("LT")
    }
    else {
      return startTime.format("LLLL z") + " to " + endTime.format("LT z") + " (" + localStartTime.format("LLLL") + " to " + localEndtime.format("LT") + " your time)"
    }
  }
  if (isSameZone) {
    return startTime.format("L LT") + " to " + endTime.format("L LT")
  }
  else {
    return startTime.format("L LT z") + " to " + endTime.format("L LT z") + " (" + localStartTime.format("L LT") + " to " + localEndtime.format("L LT") + " your time)"
  }
}