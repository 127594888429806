export const SESSION_LOGIN_REQUEST = 'SESSION_LOGIN_REQUEST'
export const SESSION_LOGIN_SUCCESS = 'SESSION_LOGIN_SUCCESS'
export const SESSION_LOGIN_FAILURE = 'SESSION_LOGIN_FAILURE'

export const SESSION_LOGOUT_REQUEST = 'SESSION_LOGOUT_REQUEST'
export const SESSION_LOGOUT_SUCCESS = 'SESSION_LOGOUT_SUCCESS'
export const SESSION_LOGOUT_FAILURE = 'SESSION_LOGOUT_SUCCESS'

export const MYPROFILE_REQUEST = 'MYPROFILE_REQUEST'
export const MYPROFILE_SUCCESS = 'MYPROFILE_SUCCESS'
export const MYPROFILE_FAILURE = 'MYPROFILE_FAILURE'

export const MYPROFILE_SAVE_REQUEST = 'MYPROFILE_SAVE_REQUEST'
export const MYPROFILE_SAVE_SUCCESS = 'MYPROFILE_SAVE_SUCCESS'
export const MYPROFILE_SAVE_FAILURE = 'MYPROFILE_SAVE_FAILURE'

export const CONFERENCE_GET_REQUEST = 'CONFERENCE_GET_REQUEST'
export const CONFERENCE_GET_SUCCESS = 'CONFERENCE_GET_SUCCESS'
export const CONFERENCE_GET_FAILURE = 'CONFERENCE_GET_FAILURE'

export const CONFERENCE_SAVE_REQUEST = 'CONFERENCE_SAVE_REQUEST'
export const CONFERENCE_SAVE_SUCCESS = 'CONFERENCE_SAVE_SUCCESS'
export const CONFERENCE_SAVE_FAILURE = 'CONFERENCE_SAVE_FAILURE'

export const ERROR_PACKAGE_NOT_ENABLED = 'ERROR_PACKAGE_NOT_ENABLED'
export const ERROR_OK = 'ERROR_OK'