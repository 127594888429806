import TimeZoneHelper from './TimeZoneHelper'
import ConferenceHelper from './ConferenceHelper'
import SecurityHelper from './SecurityHelper';
import { CookieHelper } from './CookieHelper.js'

export function clearClientData() {
  TimeZoneHelper.clearTimeZone()
  ConferenceHelper.clearConference();
  SecurityHelper.clearUser();
  CookieHelper.Remove("ActionSearchHistory")
}
