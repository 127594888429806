import React, { Component } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Linkify from 'linkifyjs/react';

class About extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Card>
        <CardHeader className="bg-primary text-center">
          About
        </CardHeader>
        <CardBody>
          <Linkify>
            {this.renderDescription()}
          </Linkify>
        </CardBody>
      </Card>
    )
  }

  renderDescription = () => {
    const description = this.props.company.Description
    if (description) {
      return description
    }

    const shortDescription = this.props.company.ShortDescription
    if (shortDescription) {
      return shortDescription
    }
    return <div className="text-secondary">No information available</div>
  }
}

export default About