import React from 'react'

import Avatar from '../Controls/Avatar'
import ListRowTitle from '../Controls/List/ListRowTitle'
import ListRowSubtitle from '../Controls/List/ListRowSubtitle'

import { Row, Col } from 'reactstrap'

import classnames from 'classnames'

import BrowserLink from '../Browser/BrowserLink'
import ListRowMenu from '../Controls/List/ListRowMenu'


export default function UserRow(props) {

  const companyLink = (
    <BrowserLink
      id={props.user.Company?.Id}
      type="company"
      fallbackUrl={`/companies/view?id=${props.user.Company?.Id}`}>
      {props.user.Company?.Name}
    </BrowserLink>
  )

  return (
    <Row className={classnames("no-gutters", props.className)}>
      <Col className="col-auto mr-2">
        <Avatar src={props.user.PhotoUrl} name={props.user.FullName} />
      </Col>
      <Col>
        <ListRowTitle>
          <BrowserLink
            id={props.user.Id}
            type="user"
            fallbackUrl={`/users/view?id=${props.user.Id}`}>
            {props.user.FullName}
          </BrowserLink>
        </ListRowTitle>
        <ListRowSubtitle>
          {
            props.user.Title && props.user.Company ?
              <>
                {props.user.Title} at {companyLink}
              </>
              :
              <>
                {
                  props.user.Title &&
                  <>
                    {props.user.Title}
                  </>
                }
                {
                  props.user.Company && companyLink
                }
              </>
          }
        </ListRowSubtitle>
      </Col>
      <Col className="col-auto">
        <ListRowMenu>
          {React.Children.toArray(props.children)}
        </ListRowMenu>
      </Col>
    </Row>
  )
}