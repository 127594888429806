import serverAPI from '../../serverAPI'
import { clearClientData } from '../../helpers/ClientDataHelper'

import {
  SESSION_LOGIN_REQUEST,
  SESSION_LOGIN_SUCCESS,
  SESSION_LOGIN_FAILURE,

  SESSION_LOGOUT_REQUEST,
  SESSION_LOGOUT_SUCCESS,
  SESSION_LOGOUT_FAILURE
}
from '../actionTypes'

const login = () => ({
  type: SESSION_LOGIN_REQUEST
})

const loginOk = (res) => ({
  type: SESSION_LOGIN_SUCCESS,
  payload: {
    ...res
  }
})

const loginFailed = (res) => ({
  type: SESSION_LOGIN_FAILURE,
  payload: {
    errorMessage: res.message
  }
})

export function loginAction(domain, userName, password) {
  return function (dispatch) {
    dispatch(login())
    serverAPI("login", "get", {
      domain: domain,
      username: userName,
      password: password,
      method: "cookie",
      application: "webapplication"
    })
    .then(res => {
      dispatch(loginOk(res))
    })
    .catch(e => {
      dispatch(loginFailed(e))
    })
  }
}

export function loginWithTokenAction(token) {
  return function (dispatch) {
    dispatch(login())
    serverAPI("loginwithtoken", "get", {
      token: token,
      method: "cookie",
      application: "webapplication"
    })
    .then(res => {
      dispatch(loginOk(res))
    })
    .catch(e => {
      dispatch(loginFailed(e))
    })
  }
}

const logout = () => ({
  type: SESSION_LOGOUT_REQUEST
})

export const logoutOk = res => {
  clearClientData() 
  return { type: SESSION_LOGOUT_SUCCESS }
}

const logoutFailed = (res) => ({
  type: SESSION_LOGOUT_FAILURE,
  payload: {
    errorMessage: res.message
  }
})

export function logoutAction() {
  return function (dispatch) {
    dispatch(logout())
    serverAPI("logout", "get", { method: "cookie" })
    .then(res => {
      dispatch(logoutOk(res))
    })
    .catch(e => {
      dispatch(logoutFailed(e))
    })
  }
}

export function clearSessionAction() {
  return function (dispatch) {
    dispatch(logoutOk())
  }
}