import React from 'react'

import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment';

class DateTimePicker extends React.Component {
  handleChange = (d) => {
    this.props.onChange(d)
  }

  render() {
    return (
      <ReactDatePicker
        id={this.props.id}
        className={"form-control " + this.props.className}
        selected={this.props.value}
        required={this.props.required}
        placeholderText={this.props.placeholderText}
        onChangeRaw={(e) => {
          let value = moment(e.target.value, this.props.momentFormat);
          if (value.isValid())
            this.handleChange(value.toDate());
        }}
        onChange={this.props.onChange}
        timeFormat={this.props.timeFormat}
        dateFormat={this.props.dateFormat}
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
        startDate={this.props.startDate}
        endDate={this.props.endDate}
        selectsStart={this.props.selectsStart}
        selectsEnd={this.props.selectsEnd}
        showTimeSelect={this.props.showTime}
        showTimeSelectOnly={this.props.showTimeOnly}
        disabled={this.props.disabled}
        timeIntervals={this.props.timeIntervals}
        disabledKeyboardNavigation
      />
    )
  }
}

DateTimePicker.defaultProps = {
  className: "",
  timeFormat: "h:mm aa",
  dateFormat: "MM/dd/yyyy h:mm aa",
  momentFormat: "MM/DD/YYYY h:mm a",
  showTime: true
}

export default DateTimePicker;
