import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.scss';

import { connect } from 'react-redux';

import queryString from 'query-string';

import { CookieHelper } from './helpers/CookieHelper.js';

import Loading from './components/Loading';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';
import AlertContentTemplate from './components/AlertContentTemplate';
import BoothLayout from './containers/VirtualBoothLayout/BoothLayout';

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Default = React.lazy(() => import('./views/Pages/Default'));
const Landing = React.lazy(() => import('./views/Pages/Landing/Landing'));

// Specific pages
const DownloadLeads = React.lazy(() => import('./views/Pages/Lead/Download'));
const SetPassword = React.lazy(() => import('./views/Pages/User/SetPassword'));
const CreateAccount = React.lazy(() => import('./views/Pages/Account/Create'));
const PayAccount = React.lazy(() => import('./views/Pages/Account/Pay'));
const OrderCompleted = React.lazy(() => import('./views/Pages/LicensePurchaseOrder/Completed')); 
const ExhibitorPurchaseCompleted = React.lazy(() => import('./views/Pages/Company/PurchaseCompleted'));
const WorkshopTransactionCompleted= React.lazy(() => import('./views/Pages/Workshop/Transaction/WorkshopTransactionCompleted')); 

// Create session by token
const OpenSession = React.lazy(() => import('./views/Pages/OpenSession'));

class App extends Component {

  pushDefaultRoute = props => {
    if (props.location.pathname !== "/") props.history.push("/")
  }

  defaultPage = props => {
    const params = queryString.parse(props.location.search)
    if (props.location.pathname === "/" && params.token) {
      return <OpenSession token={params.token} {...props} />
    }

    if (this.props.isLoggedIn) {
      return <DefaultLayout {...props} />
    }

    if (!params.noredir) {
      const station = CookieHelper.Get("CheckInStation")
      if (params.domain || station)
        return <Default domain={params.domain} station={station} {...props} />
    }

    if (props.location.pathname === "/login" || params.fwd) {
      return <Login />
    }

    this.pushDefaultRoute(props)
    return <Landing {...props} />
  }

  render() {
    return (
      <>
        <HashRouter>
          <React.Suspense fallback={<Loading />}>
            <Switch>
              <Route path="/myleads" name="Download Leads" render={p => <DownloadLeads {...p} />} />
              <Route path="/resetpassword" name="Reset Password" render={p => <SetPassword {...p} />} />
              <Route path="/createaccount" name="Create Account" render={p => <CreateAccount {...p} />} />
              <Route path="/payaccount" name="Pay Account" render={p => <PayAccount {...p} />} />
              <Route path="/paymentcompleted" name="Order Completed" render={p => <OrderCompleted {...p} />} />
              <Route path="/exhibitor/completepurchase" name="Purchase Order Completed" render={p => <ExhibitorPurchaseCompleted {...p} />} />   
              <Route path="/workshop/completetransaction" name="Session Transaction Completed" render={p => <WorkshopTransactionCompleted {...p} />} />  
              {
                this.props.isLoggedIn &&
                <Route path="/exhibitors/virtual/booth" name="Virtual Booth" render={p => <BoothLayout {...p} />} />
              }
              <Route path="/" name="Home" render={this.defaultPage} />
            </Switch>
          </React.Suspense>
        </HashRouter>
        <Alert stack={{ limit: 3 }} effect="stackslide" position="top" contentTemplate={AlertContentTemplate} />
      </>
    );
  }
}

const mapStateToProps = state => {
  const { session } = state;
  return { isLoggedIn: session.active }
}

export default connect(mapStateToProps)(App);
