import React, { Component } from 'react';

import ReactAvatar, {Cache} from 'react-avatar';

import { getColorByString } from '../../helpers/ColorHelper';

const cache = new Cache();

class Avatar extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      color: 'transparent'
    }
  }

  componentDidMount(){
    setTimeout(() => this.setState({
      color: this.props.color || getColorByString(this.props.name, '600')
    }), 0);
  }

  render(){
    return (
      <ReactAvatar 
        src={this.props.src}
        cache={cache}
        className={this.props.className + " react-avatar"}
        name={this.props.name}
        round={true}
        size={this.props.size}
        color={this.state.color}
        maxInitials={this.props.maxInitials} 
      />
    )
  }
}

Avatar.defaultProps = {
  size: "36",
  maxInitials: 2,
  className: ""
}

export default Avatar;