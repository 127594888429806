import {
  ERROR_PACKAGE_NOT_ENABLED,
  ERROR_OK
}
from '../actionTypes'

export function packageNotEnabled(res) {
  return {
    type: ERROR_PACKAGE_NOT_ENABLED,
    payload: {
      ...res
    }
  }
}

export function noError() {
  return {
    type: ERROR_OK
  }
}