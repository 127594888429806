import React from 'react'

export default function CalendarIcon(props) {
  return (
    <div className="border rounded-top rounded-bottom text-uppercase" {...props}>
      <div className="small rounded-top bg-danger">
        {props.moment.format("MMM")}
      </div>
      <div className="bg-white rounded-bottom">
        {props.moment.format("D")}
      </div>
    </div>
  )
} 