import React from 'react'
import { withRouter } from 'react-router-dom'

import { getBrowsedEntityUrl } from '../../helpers/NavigationHelper'

import classnames from 'classnames'

class BrowserLink extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  handleClick = e => {
    e.preventDefault()
    
    if (!this.props.type && !this.props.id) return

    const url = getBrowsedEntityUrl(this.props.location, 
      this.props.type, 
      this.props.id,
      this.props.fallbackUrl,
      this.props.tool)
    
    this.props.history.push(url)
  }

  render() {
    return (
      <a 
        href="/"  
        className={classnames("m-0 p-0 align-baseline", this.props.className)} 
        onClick={this.handleClick}>
        {this.props.children}
      </a>
    )
  }
}

export default withRouter(BrowserLink)