import React from 'react';
import { ATTENDEE_EXPERIENCE_PACKAGE, EXHIBITOR_PACKAGE, PRE_CONFERENCE_PACKAGE, PACKAGE_CHECK_METHOD_ONE_OF_ALL, REGSTRATION_PACKAGE, PACKAGE_CHECK_METHOD_FIRST_AND_ONE_OF_REST} from './helpers/PackageHelper';
import SwipeQRLanding from './views/Pages/Swipe/SwipeQRLanding';

// Dashboard
const Dashboard = React.lazy(() => import('./views/Pages/Dashboard/Dashboard'));

// Conference
const ConferenceSetup = React.lazy(() => import('./views/Pages/Conference/Setup'));
const ConferenceSettings = React.lazy(() => import('./views/Pages/Conference/Settings'));
const ConferenceSettingsSection = React.lazy(() => import('./views/Pages/Conference/SettingsSection'));
const ConferenceDetails = React.lazy(() => import('./components/Browser/Details/ConferenceDetails'));


// Conference Setup
const ConferenceCustomLandingPageSetup = React.lazy(() => import('./views/Pages/ConferenceSetup/CustomLandingPageSetup'));


// Workshop
const WorkshopList = React.lazy(() => import('./views/Pages/Workshop/List'));
const WorkshopEdit = React.lazy(() => import('./views/Pages/Workshop/Edit'));
const WorkshopView = React.lazy(() => import('./views/Pages/Workshop/View'));
const WorkshopDownload = React.lazy(() => import('./views/Pages/Workshop/Download'));
const WorkshopEditStreaming = React.lazy(() => import('./views/Pages/Workshop/EditStreaming'));
const WorkshopUploadStreaming = React.lazy(() => import('./views/Pages/Workshop/UploadStreaming'));
const WorkshopStartStreaming = React.lazy(() => import('./views/Pages/Workshop/StartStreaming'));
const WorkshopPreviewStreaming = React.lazy(() => import('./views/Pages/Workshop/PreviewStreaming'));
const WorkshopRegistration = React.lazy(() => import('./views/Pages/Workshop/Registration'));
const WorkshopQuiz = React.lazy(() => import('./views/Pages/Workshop/Quiz'));
const WorkshopStreaming = React.lazy(() => import('./views/Pages/Workshop/WatchStreaming'));
const WorkshopScheduleViewer = React.lazy(() => import('./views/Pages/Workshop/ScheduleViewer'));

// Account
const AccountList = React.lazy(() => import('./views/Pages/Account/List'));
const AccountEdit = React.lazy(() => import('./views/Pages/Account/Edit'));
const AccountGroupList = React.lazy(() => import('./views/Pages/Account/ListGroup'));
const AccountGroupEdit = React.lazy(() => import('./views/Pages/Account/EditGroup'));
const AccountTools = React.lazy(() => import('./views/Pages/Account/Tools'));
const ProductAnnouncementList = React.lazy(() => import('./views/Pages/Account/ListProductAnnouncement'));
const ProductAnnouncementEdit = React.lazy(() => import('./views/Pages/Account/EditProductAnnouncement'));

// License
const AccountLicense = React.lazy(() => import('./views/Pages/Account/License'));
const AccountPurchaseOrders = React.lazy(() => import('./views/Pages/Account/PurchaseOrders'));

// Handout
const HandoutList = React.lazy(() => import('./views/Pages/Handout/List'));
const HandoutEdit = React.lazy(() => import('./views/Pages/Handout/Edit'));

// Map
const MapList = React.lazy(() => import('./views/Pages/Map/List'));
const MapEdit = React.lazy(() => import('./views/Pages/Map/Edit'));
const MapView = React.lazy(() => import('./views/Pages/Map/View'));

// Room
const RoomList = React.lazy(() => import('./views/Pages/Room/List'));
const RoomEdit = React.lazy(() => import('./views/Pages/Room/Edit'));

// Track
const TrackList = React.lazy(() => import('./views/Pages/Track/List'));
const TrackEdit = React.lazy(() => import('./views/Pages/Track/Edit'));

// Company
const CompanyList = React.lazy(() => import('./views/Pages/Company/List'));
const CompanyEdit = React.lazy(() => import('./views/Pages/Company/Edit'));
const CompanyView = React.lazy(() => import('./views/Pages/Company/View'));
const CompanyMine = React.lazy(() => import('./views/Pages/Company/Mine'));
const CompanyLimits = React.lazy(() => import('./views/Pages/Company/Limits'));
const SponsorshipList = React.lazy(() => import('./views/Pages/Sponsorship/List'));
const SponsorshipEdit = React.lazy(() => import('./views/Pages/Sponsorship/Edit'));

// Profile
const Profile = React.lazy(() => import('./views/Pages/User/Profile'));
const ChangePassword = React.lazy(() => import('./views/Pages/User/ChangePassword'));

// User
const UserList = React.lazy(() => import('./views/Pages/User/List'));
const UserEdit = React.lazy(() => import('./views/Pages/User/Edit'));
const UserView = React.lazy(() => import('./views/Pages/User/View'));
const UserPrintLabels = React.lazy(() => import('./views/Pages/User/PrintLabels'));
const UserAnalytics = React.lazy(() => import('./views/Pages/User/ContactInfoStats'));
const AttendeeWorkshops = React.lazy(() => import('./views/Pages/User/AttendeeWorkshops'));
const PresenterWorkshops = React.lazy(() => import('./views/Pages/User/PresenterWorkshops'));
const UserEmailBroadcast = React.lazy(() => import('./views/Pages/User/EmailBroadcast'));
const GroupList = React.lazy(() => import('./views/Pages/Group/List'));
const GroupEdit = React.lazy(() => import('./views/Pages/Group/Edit'));
const GroupMembers = React.lazy(() => import('./views/Pages/Group/Members'));
const GroupUser = React.lazy(() => import('./views/Pages/Group/User'));
const CheckInList = React.lazy(() => import('./views/Pages/CheckIn/List'));
const CheckIn = React.lazy(() => import('./views/Pages/CheckIn/CheckIn'));

// Contact
const ContactList = React.lazy(() => import('./views/Pages/Contact/List'));
const ContactEdit = React.lazy(() => import('./views/Pages/Contact/Edit'));

// Contact Notifications
const ContactNotificationList = React.lazy(() => import('./views/Pages/ContactNotification/List'));
const ContactNotificationEdit = React.lazy(() => import('./views/Pages/ContactNotification/Edit'));

// Registration
const RegistrationList = React.lazy(() => import('./views/Pages/Registration/List'));
const RegistrationEdit = React.lazy(() => import('./views/Pages/Registration/Edit'));
const RegistrationEditExhibitor = React.lazy(() => import('./views/Pages/Registration/EditExhibitor'));
const RegistrationShare = React.lazy(() => import('./views/Pages/Registration/Share'));
const RegistrationReport = React.lazy(() => import('./views/Pages/Registration/SummaryReport'));

// Notification
const NotificationList = React.lazy(() => import('./views/Pages/Notification/List'));
const NotificationEdit = React.lazy(() => import('./views/Pages/Notification/Edit'));

// Template
const TemplateList = React.lazy(() => import('./views/Pages/Template/List'));
const TemplateEdit = React.lazy(() => import('./views/Pages/Template/Edit'));
const TemplateDefaults = React.lazy(() => import('./views/Pages/Template/Defaults'));
const TemplateLayout = React.lazy(() => import('./views/Pages/Template/Layout'));
const TemplateDownload = React.lazy(() => import('./views/Pages/Template/Download'));
const TemplateEmail = React.lazy(() => import('./views/Pages/Template/Email'));

// Attendance
const AttendanceLogList = React.lazy(() => import('./views/Pages/Attendance/List'));
const AttendanceLogEdit = React.lazy(() => import('./views/Pages/Attendance/Edit'));
const AttendanceLogFixAll = React.lazy(() => import('./views/Pages/Attendance/FixAll'));
const SwipeList = React.lazy(() => import('./views/Pages/Swipe/List'));
const SwipeResolve = React.lazy(() => import('./views/Pages/Swipe/Resolve'));
const SwipeResolveAll = React.lazy(() => import('./views/Pages/Swipe/ResolveAll'));
const SwipeCard = React.lazy(() => import('./views/Pages/Swipe/SwipeCard'));
const SwipeQRShow = React.lazy(() => import('./views/Pages/Swipe/SwipeQRShow'));
const SwipeQRPrint = React.lazy(() => import('./views/Pages/Swipe/SwipeQRPrint'));
const CheckInStationList = React.lazy(() => import('./views/Pages/CheckInStation/List'));
const CheckInStationEdit = React.lazy(() => import('./views/Pages/CheckInStation/Edit'));
const AnnouncementList = React.lazy(() => import('./views/Pages/Announcement/List'));
const AnnouncementListExhibitor = React.lazy(() => import('./views/Pages/Announcement/ListExhibitor'));
const AnnouncementListMine = React.lazy(() => import('./views/Pages/Announcement/ListMine'));
const AnnouncementEdit = React.lazy(() => import('./views/Pages/Announcement/Edit'));
const AnnouncementView = React.lazy(() => import('./views/Pages/Announcement/View'));

// Import
const ImportUpload = React.lazy(() => import('./views/Pages/Import/Upload'));
const ImportLearn = React.lazy(() => import('./views/Pages/Import/Learn'));

// Export
const ExportDownload = React.lazy(() => import('./views/Pages/Export/Download'));

// Interaction
const InteractionPost = React.lazy(() => import('./views/Pages/Interaction/Post'));
const InteractionModerate = React.lazy(() => import('./views/Pages/Interaction/Moderate'));
const InteractionGallery = React.lazy(() => import('./views/Pages/Interaction/Gallery')); 
const InteractionWall = React.lazy(() => import('./views/Pages/Interaction/Wall'));

// Lead
const LeadList = React.lazy(() => import('./views/Pages/Lead/List'));
const LeadEdit = React.lazy(() => import('./views/Pages/Lead/Edit'));
const LeadForm = React.lazy(() => import('./views/Pages/Lead/Form'));
const LeadFormBulk = React.lazy(() => import('./views/Pages/Lead/FormBulk'));
const LeadEmailBroadcast = React.lazy(() => import('./views/Pages/Lead/EmailBroadcast'));
const LeadAwards = React.lazy(() => import('./views/Pages/Lead/Awards'));
const LeadTopVisitors = React.lazy(() => import('./views/Pages/Lead/TopVisitors'));
const LeadDrawing = React.lazy(() => import('./views/Pages/Lead/Drawing'));
const LeadEmail = React.lazy(() => import('./views/Pages/Lead/Email'));

//Ads
const AdCenter = React.lazy(() => import('./views/Pages/Ads/AdCenter'));
const LeadAds = React.lazy(() => import('./views/Pages/Ads/Lead/Leads'));
const LeadAdsPush = React.lazy(() => import('./views/Pages/Ads/Lead/PushAd'));


// Reports
const AnalyticsDashboard = React.lazy(() => import('./views/Pages/Report/Dashboard'));
const ReportLeads = React.lazy(() => import('./views/Pages/Report/Leads'));
const ReportWorkshop = React.lazy(() => import('./views/Pages/Report/Workshop'));
const ReportWorkshopAbsentees = React.lazy(() => import('./views/Pages/Report/WorkshopAbsentees'));
const ReportReps = React.lazy(() => import('./views/Pages/Report/Reps'));
const ReportAttendee = React.lazy(() => import('./views/Pages/Report/Attendee'));
const ReportAttendance = React.lazy(() => import('./views/Pages/Report/Attendance'));
const ReportSocial = React.lazy(() => import('./views/Pages/Report/Social'));
const InterestedAttendees = React.lazy(() => import('./views/Pages/Report/InterestedAttendees'));

// Survey
const SurveyList = React.lazy(() => import('./views/Pages/Survey/List'));
const SurveyEdit = React.lazy(() => import('./views/Pages/Survey/Edit'));
const SurveyResults = React.lazy(() => import('./views/Pages/Survey/Results'));
const SurveyAnswered = React.lazy(() => import('./views/Pages/Survey/Answered'));

// SessionEvaluation
const SessionEvaluation = React.lazy(() => import('./views/Pages/SessionEvaluation/SessionEvaluation'));
const SessionEvaluationEdit = React.lazy(() => import('./views/Pages/SessionEvaluation/Edit'));
const SessionEvaluationSummaryReport = React.lazy(() => import('./views/Pages/SessionEvaluation/SummaryReport'));
const SessionEvaluationSessions = React.lazy(() => import('./views/Pages/SessionEvaluation/Sessions'));
const SessionEvaluationSessionReport = React.lazy(() => import('./views/Pages/SessionEvaluation/SessionReport'));
const SessionEvaluationAnalytics = React.lazy(() => import('./views/Pages/SessionEvaluation/Analytics'));

// PresenterSessionEvaluation
const PresenterSessionEvaluation = React.lazy(() => import('./views/Pages/PresenterSessionEvaluation/PresenterSessionEvaluation'));
const PresenterSessionEvaluationSessions = React.lazy(() => import('./views/Pages/PresenterSessionEvaluation/PresenterSessions'));
const PresenterSessionEvaluationAnalytics = React.lazy(() => import('./views/Pages/PresenterSessionEvaluation/PresenterAnalytics'));
const PresenterSessionEvaluationSessionReport = React.lazy(() => import('./views/Pages/PresenterSessionEvaluation/PresenterSessionReport'));

// Newsletters
const NewsletterList = React.lazy(() => import('./views/Pages/Newsletter/List'));
const NewsletterEdit = React.lazy(() => import('./views/Pages/Newsletter/Edit'));
const NewsletterSend = React.lazy(() => import('./views/Pages/Newsletter/Send'));

// Advanced options
const DiscountCodeList = React.lazy(() => import('./views/Pages/DiscountCode/List'));
const DiscountCodeEdit = React.lazy(() => import('./views/Pages/DiscountCode/Edit'));
const EmailDeliveryLogList = React.lazy(() => import('./views/Pages/EmailDeliveryLog/List'));
const DeviceList = React.lazy(() => import('./views/Pages/Device/List'));
const DeviceMessage = React.lazy(() => import('./views/Pages/Device/Message'));
const SessionList = React.lazy(() => import('./views/Pages/Session/List'));
const StaffMessaging = React.lazy(() => import('./views/Pages/User/StaffMessaging'));
const AdvancedSearch = React.lazy(() => import('./views/Pages/Search/AdvancedSearch'));

// Mobile Ads
const MobileAdList = React.lazy(() => import('./views/Pages/MobileAd/List'));
const MobileAdEdit = React.lazy(() => import('./views/Pages/MobileAd/Edit'));
const MobileAdSummary = React.lazy(() => import('./views/Pages/MobileAd/Summary'));

// Forms
const FormEdit = React.lazy(() => import('./views/Pages/Form/Edit'));

// Attendee Site
const AttendeeList = React.lazy(() => import('./views/Pages/AttendeeSite/User/AttendeeList'));
const RankingList = React.lazy(() => import('./views/Pages/AttendeeSite/User/RankingList'));
const PresenterList = React.lazy(() => import('./views/Pages/AttendeeSite/User/PresenterList'));
const MySurveyList = React.lazy(() => import('./views/Pages/AttendeeSite/Survey/List'));
const MySurveyAnswer = React.lazy(() => import('./views/Pages/AttendeeSite/Survey/Answer'));
const ChatSummary = React.lazy(() => import('./views/Pages/Interaction/ChatSummary'));
const Chat = React.lazy(() => import('./views/Pages/Interaction/Chat'));
const PostView = React.lazy(() => import('./views/Pages/Interaction/PostView'));
const AppointmentList = React.lazy(() => import('./views/Pages/AttendeeSite/Appointment/List'));
const AppointmentEdit = React.lazy(() => import('./views/Pages/AttendeeSite/Appointment/Edit'));
const AppointmentView = React.lazy(() => import('./views/Pages/AttendeeSite/Appointment/View'));
const AttendeeSiteLogList = React.lazy(() => import('./views/Pages/AttendeeSite/Attendance/List'));
const AttendeeSiteWorkshopList = React.lazy(() => import('./views/Pages/AttendeeSite/Workshop/List'));
const AttendeeSiteEditWorkshops = React.lazy(() => import('./views/Pages/AttendeeSite/Workshop/EditWorkshops'));
const AttendeeSiteLogView = React.lazy(() => import('./views/Pages/AttendeeSite/Attendance/View'));
const NoteList = React.lazy(() => import('./views/Pages/Note/List'));
const NoteEdit = React.lazy(() => import('./views/Pages/Note/Edit'));
const FileList = React.lazy(() => import('./views/Pages/File/List'));
const EvaluationsList = React.lazy(() => import('./views/Pages/AttendeeSite/SessionEvaluation/List'));
const EvaluationsAnswer = React.lazy(() => import('./views/Pages/AttendeeSite/SessionEvaluation/Answer'));
const VirtualExhibitHall = React.lazy(() => import('./views/Pages/AttendeeSite/VirtualExhibit/VirtualExhibitHall'));

// LicensePlan
const PlanList = React.lazy(() => import('./views/Pages/LicensePlan/List'));
const PlanEdit = React.lazy(() => import('./views/Pages/LicensePlan/Edit'));

// LicensePackage
const PackageList = React.lazy(() => import('./views/Pages/LicensePackage/List'));
const PackageEdit = React.lazy(() => import('./views/Pages/LicensePackage/Edit'));

// Prize
const PrizeWheelsList = React.lazy(() => import('./views/Pages/PrizeWheel/List'));
const PrizeWheelEdit = React.lazy(() => import('./views/Pages/PrizeWheel/Edit'));
const PrizeWheelSpin = React.lazy(() => import('./views/Pages/PrizeWheel/Spin'));
const PrizesList = React.lazy(() => import('./views/Pages/Prize/List'));
const PrizeEdit = React.lazy(() => import('./views/Pages/Prize/Edit'));
const PrizeDetail = React.lazy(() => import('./views/Pages/Prize/Detail'));

//Tickets
const TicketList = React.lazy(() => import('./views/Pages/Ticket/List'));
const VictoryList = React.lazy(() => import('./views/Pages/Victory/List'));


//About
const About = React.lazy(() => import('./views/Pages/About/About'));


// Exhibitor Purchase 
const ExhibitorLicensePurchase = React.lazy(() => import('./views/Pages/Company/ExhibitorLicensePurchase'));
const ExhibitorPurchases = React.lazy(() => import('./views/Pages/Company/ExhibitorPurchases'));


// Schedule at a Glance
const ScheduleList = React.lazy(() => import('./views/Pages/ScheduleAtAGlance/List'));
const ScheduleEdit = React.lazy(() => import('./views/Pages/ScheduleAtAGlance/Edit'));
const ScheduleAppearance = React.lazy(() => import('./views/Pages/ScheduleAtAGlance/Appearance'));

// Conference Evaluation
const ConferenceEvaluationDashboard = React.lazy(() => import('./views/Pages/ConferenceEvaluation/Dashboard'));
const ConferenceEvaluationList = React.lazy(() => import('./views/Pages/ConferenceEvaluation/List') )

// Workshop Transaction
const WorkshopTransactionList = React.lazy(() => import('./views/Pages/Workshop/Transaction/List'));


// Exhibit Booth
const ExhibitBoothEdit = React.lazy(() => import('./views/Pages/ExhibitBooth/Edit'));
const ExhibitBoothList = React.lazy(() => import('./views/Pages/ExhibitBooth/List'));

// Stripe
const StripeConnect = React.lazy(() => import('./views/Pages/Stripe/Connect'));

// Product Announcements
const ProductAnnouncementDetails = React.lazy(() => import('./views/Pages/ProductAnnouncement/Details'));
const MyProductAnnouncementsList = React.lazy(() => import('./views/Pages/ProductAnnouncement/MyList'));

// Workshop Proposal
const WorkshopProposalList = React.lazy(() => import('./views/Pages/WorkshopProposal/List'));
const WorkshopProposalShare = React.lazy(() => import('./views/Pages/WorkshopProposal/Share'));
const WorkshopProposalEdit = React.lazy(() => import('./views/Pages/WorkshopProposal/Edit'));
const WorkshopProposalForm = React.lazy(() => import('./views/Pages/WorkshopProposal/Form')); 
const WorkshopProposalScheduleBuilder = React.lazy(() => import('./views/Pages/WorkshopProposal/ScheduleBuilder'));

const routes = [{
  path: '/',
  exact: true,
  name: 'Home'
},

{
  path: '/dashboard',
  name: 'Dashboard',
  component: Dashboard
},

{
  path: '/accounts',
  exact: true,
  name: 'Accounts',
  component: AccountList
},
{
  path: '/accounts/edit',
  exact: true,
  name: 'Edit',
  component: AccountEdit
},
{
  path: '/accounts/groups',
  exact: true,
  name: 'Account Groups',
  component: AccountGroupList
},
{
  path: '/accounts/editgroup',
  exact: true,
  name: 'Edit Account Group',
  component: AccountGroupEdit
},
{
  path: '/accounts/tools',
  exact: true,
  name: 'Tools',
  component: AccountTools
  },
  {
    path: '/accounts/announcements',
    exact: true,
    name: 'Product Announcements',
    component: ProductAnnouncementList
  },
  {
    path: '/accounts/editannouncement',
    name: 'Edit Product Announcement',
    component: ProductAnnouncementEdit
  }, 
  {
    path: '/product-announcement/view',
    name: 'View Product Announcement',
    component: ProductAnnouncementDetails
  },
  {
    path: '/product-announcement/all',
    name: 'Product Announcements',
    component: MyProductAnnouncementsList
  },
{
  path: '/license',
  exact: true,
  name: 'Manage License',
  component: AccountLicense
},
{
  path: '/exhibitor/license',
  exact: true,
  name: 'Leads App Purchase',
  component: ExhibitorLicensePurchase
},
{
  path: '/license/purchases',
  exact: true,
  name: 'Purchase Orders',
  component: AccountPurchaseOrders
},

// Conference
{
  path: '/conference',
  exact: true,
  name: 'Conference',
  component: ConferenceSetup
},
{
  path: '/conference/setupcustomconference',
  exact: true,
  name: 'Landing Page',
  component: ConferenceCustomLandingPageSetup
},
{
  path: '/info',
  exact: true,
  name: 'Conference',
  component: props => < ConferenceDetails socialFeedWrapped isBrowsed={false} {...props} />
}, {
  path: '/conference/settings',
  exact: true,
  name: 'Settings',
  component: ConferenceSettings
},
{
  path: '/conference/settings/general',
  name: 'General',
  component: ConferenceSettingsSection
},
{
  path: '/conference/settings/appearance',
  name: 'Appearance',
  component: ConferenceSettingsSection
},
{
  path: '/conference/settings/attendee registration',
  name: 'Attendee Registration',
  component: ConferenceSettingsSection
},
{
  path: '/conference/settings/exhibitor registration',
  name: 'Exhibitor Registration',
  component: ConferenceSettingsSection
},
{
  path: '/conference/settings/payments',
  name: 'Payments',
  component: ConferenceSettingsSection
},
{
  path: '/conference/settings/attendance',
  name: 'Attendance',
  component: ConferenceSettingsSection
},
{
  path: '/conference/settings/streaming',
  name: 'Streaming',
  component: ConferenceSettingsSection
},
{
  path: '/conference/settings/custom fields',
  name: 'Custom Fields',
  component: ConferenceSettingsSection
},
{
  path: '/conference/settings/attendee portal',
  name: 'Attendee Portal',
  component: ConferenceSettingsSection
},
{
  path: '/conference/settings/exhibitor portal',
  name: 'Exhibitor Portal',
  component: ConferenceSettingsSection
},
{
  path: '/conference/settings/notifications',
  name: 'Notifications',
  component: ConferenceSettingsSection
},
{
  path: '/conference/settings/help',
  name: 'Help',
  component: ConferenceSettingsSection
},
{
  path: '/conference/settings/presenter',
  name: 'Presenter Portal',
  component: ConferenceSettingsSection
},
{
  path: '/conference/settings/sessions',
  name: 'Sessions',
  component: ConferenceSettingsSection
},
// Workshop
{
  path: '/sessions',
  exact: true,
  name: 'Sessions',
  component: WorkshopList
},
{
  path: '/sessions/edit',
  name: 'Edit',
  component: WorkshopEdit
},
{
  path: '/sessions/view',
  name: 'View',
  component: WorkshopView
},
{
  path: '/sessions/download',
  name: 'Download',
  component: WorkshopDownload
},
{
  path: '/sessions/editstreaming',
  name: 'Streaming Setup',
  component: WorkshopEditStreaming
},
{
  path: '/sessions/uploadstreaming',
  name: 'Upload Streaming',
  component: WorkshopUploadStreaming
},
{
  path: '/sessions/startstreaming',
  name: 'Start Streaming',
  component: WorkshopStartStreaming
},
{
  path: '/sessions/previewstreaming',
  name: 'Preview Streaming',
  component: WorkshopPreviewStreaming
},
{
  path: '/sessions/registration',
  name: 'Registration',
  component: WorkshopRegistration
},
{
  path: '/sessions/schedule',
  name: 'Sessions Schedule Viewer',
  component: WorkshopScheduleViewer
}, 
{
  path: '/quiz',
  name: 'Quiz',
  component: WorkshopQuiz
},
{
  path: '/streaming',
  name: 'Streaming',
  component: WorkshopStreaming
},

// Handout
{
  path: '/handouts',
  exact: true,
  name: 'Handouts',
  component: HandoutList
},
{
  path: '/handouts/edit',
  name: 'Edit',
  component: HandoutEdit
},

// Map
{
  path: '/maps',
  exact: true,
  name: 'Maps',
  component: MapList
},
{
  path: '/maps/edit',
  name: 'Edit',
  component: MapEdit
},
{
  path: '/maps/view',
  name: 'View',
  component: MapView
},

// Room
{
  path: '/rooms',
  exact: true,
  name: 'Rooms',
  component: RoomList
},
{
  path: '/rooms/edit',
  name: 'Edit',
  component: RoomEdit
},

// Track
{
  path: '/tracks',
  exact: true,
  name: 'Tracks',
  component: TrackList
},
{
  path: '/tracks/edit',
  name: 'Edit',
  component: TrackEdit
},

// Company
{
  path: '/companies',
  exact: true,
  name: 'Companies',
  component: CompanyList
},
{
  path: '/companies/edit',
  name: 'Edit',
  component: CompanyEdit
},
{
  path: '/companies/view',
  name: 'View',
  component: CompanyView
},
{
  path: '/companies/mine',
  name: 'Mine',
  component: CompanyMine
},
{
  path: '/companies/limits',
  name: 'Manage Limits',
  component: CompanyLimits
},
{
  path: '/companies/sponsorships',
  exact: true,
  name: 'Sponsor Types',
  component: SponsorshipList
},
{
  path: '/companies/sponsorships/edit',
  name: 'Edit',
  component: SponsorshipEdit
},
{
  path: '/exhibitor/purchases',
  name: 'Exhibitor Purchases',
  component: ExhibitorPurchases
},
// Profile
{
  path: '/profile',
  exact: true,
  name: 'Your Profile',
  component: Profile
},
{
  path: '/profile/password',
  exact: true,
  name: 'Change Password',
  component: ChangePassword
},

// User
{
  path: '/users',
  exact: true,
  name: 'Users',
  component: UserList
},
{
  path: '/users/edit',
  name: 'Edit',
  component: UserEdit
},
{
  path: '/users/view',
  name: 'View',
  component: UserView
},
{
  path: '/users/administrator',
  name: 'Administrators',
  component: UserList
},
{
  path: '/users/moderator',
  name: 'Moderators',
  component: UserList,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},
{
  path: '/users/operator',
  name: 'Operators',
  component: UserList
},
{
  path: '/users/groups',
  exact: true,
  name: 'Groups',
  component: GroupList
},
{
  path: '/users/groups/edit',
  name: 'Edit',
  component: GroupEdit
},
{
  path: '/users/groups/members',
  name: 'Members',
  component: GroupMembers
},
{
  path: '/users/groups/user',
  name: 'User Groups',
  component: GroupUser
},
{
  path: '/users/attendee',
  exact: true,
  name: 'Attendees',
  component: UserList
},
{
  path: '/users/attendee/sessions',
  exact: true,
  name: 'Registration',
  component: AttendeeWorkshops
},
{
  path: '/users/presenter',
  exact: true,
  name: 'Presenters',
  component: UserList
},
{
  path: '/users/presenter/sessions',
  exact: true,
  name: 'Registration',
  component: PresenterWorkshops
},
{
  path: '/users/attendee/analytics',
  name: 'Contact Information Analytics',
  component: UserAnalytics
},
{
  path: '/users/printlabels',
  name: 'Print Labels',
  component: UserPrintLabels
},
{
  path: '/users/checkins',
  exact: true,
  name: 'Check-Ins',
  component: CheckInList
},
{
  path: '/users/checkins/checkin',
  name: 'Check Attendee In',
  component: CheckIn
},
{
  path: '/users/emailbroadcast',
  name: 'Email Broadcast',
  component: UserEmailBroadcast
},

// Contact
// We have renamed Contacts to Prospects. We are changing only the labels. So please try not to change paths, file names or similar issues
{
  path: '/prospects',
  exact: true,
  name: 'Prospects',
  component: ContactList,
  packages: [PRE_CONFERENCE_PACKAGE]
},
{
  path: '/prospects/edit',
  name: 'Edit',
  component: ContactEdit
},
// Contact Notifications
{
  path: '/prospectnotifications',
  exact: true,
  name: 'Prospect Notifications',
  component: ContactNotificationList
},
{
  path: '/prospectnotifications/edit',
  name: 'Edit',
  component: ContactNotificationEdit
},

// Notification
{
  path: '/users/attendee/notifications',
  exact: true,
  name: 'SMS Notifications',
  component: NotificationList
},
{
  path: '/users/attendee/notifications/edit',
  name: 'Edit',
  component: NotificationEdit
},

// Registration
{
  path: '/users/registrations/attendee',
  exact: true,
  name: 'Attendee Registration',
  component: RegistrationList, 
  packages: [PRE_CONFERENCE_PACKAGE, REGSTRATION_PACKAGE],
  packageCheckMethod: PACKAGE_CHECK_METHOD_ONE_OF_ALL
},
{
  path: '/users/registrations/attendee/share',
  name: 'Share',
  component: RegistrationShare
},
{
  path: '/users/registrations/exhibitor',
  exact: true,
  name: 'Exhibitor Registration',
  component: RegistrationList,
  packages: [EXHIBITOR_PACKAGE, PRE_CONFERENCE_PACKAGE, REGSTRATION_PACKAGE],
  packageCheckMethod: PACKAGE_CHECK_METHOD_FIRST_AND_ONE_OF_REST 
},
{
  path: '/users/registrations/exhibitor/share',
  exact: true,
  name: 'Share',
  component: RegistrationShare
},
{
  path: '/users/registrations/edit',
  name: 'Online Registration',
  component: RegistrationEdit
},
{
  path: '/users/registrations/editexhibitor',
  name: 'Online Registration',
  component: RegistrationEditExhibitor
},
{
  path: '/users/registrations/attendee/form',
  name: 'Form',
  component: FormEdit
},
{
  path: '/users/registrations/exhibitor/form',
  name: 'Form',
  component: FormEdit
},

// Template
{
  path: '/templates/badge',
  exact: true,
  name: 'Badges',
  component: TemplateList
},
{
  path: '/templates/badge/edit',
  name: 'Edit',
  component: TemplateEdit
},
{
  path: '/templates/badge/defaults',
  name: 'Defaults',
  component: TemplateDefaults
},
{
  path: '/templates/badge/layout',
  name: 'Design',
  component: TemplateLayout
},
{
  path: '/templates/badge/print',
  name: 'Print',
  component: TemplateDownload
},
{
  path: '/templates/badge/email',
  name: 'Email',
  component: TemplateEmail
},

{
  path: '/templates/certificate',
  exact: true,
  name: 'Certificates',
  component: TemplateList
},
{
  path: '/templates/certificate/edit',
  name: 'Edit',
  component: TemplateEdit
},
{
  path: '/templates/certificate/defaults',
  name: 'Defaults',
  component: TemplateDefaults
},
{
  path: '/templates/certificate/layout',
  name: 'Design',
  component: TemplateLayout
},
{
  path: '/templates/certificate/print',
  name: 'Print',
  component: TemplateDownload
},
{
  path: '/templates/certificate/email',
  name: 'Email',
  component: TemplateEmail
},

{
  path: '/templates/transcript',
  exact: true,
  name: 'CEU Reports',
  component: TemplateList
},
{
  path: '/templates/transcript/edit',
  name: 'Edit',
  component: TemplateEdit
},
{
  path: '/templates/transcript/defaults',
  name: 'Defaults',
  component: TemplateDefaults
},
{
  path: '/templates/transcript/layout',
  name: 'Design',
  component: TemplateLayout
},
{
  path: '/templates/transcript/print',
  name: 'Print',
  component: TemplateDownload
},
{
  path: '/templates/transcript/email',
  name: 'Email',
  component: TemplateEmail
},

{
  path: '/templates/invoice',
  exact: true,
  name: 'Invoices',
  component: TemplateList,
  packages: [PRE_CONFERENCE_PACKAGE]
},
{
  path: '/templates/invoice/edit',
  name: 'Edit',
  component: TemplateEdit
},
{
  path: '/templates/invoice/defaults',
  name: 'Defaults',
  component: TemplateDefaults
},
{
  path: '/templates/invoice/layout',
  name: 'Design',
  component: TemplateLayout
},
{
  path: '/templates/invoice/print',
  name: 'Print',
  component: TemplateDownload,
  packages: [PRE_CONFERENCE_PACKAGE]
},
{
  path: '/templates/invoice/email',
  name: 'Email',
  component: TemplateEmail,
  packages: [PRE_CONFERENCE_PACKAGE]
},

// Attendance
{
  path: '/attendance',
  exact: true,
  name: 'Attendance Logs',
  component: AttendanceLogList
},
{
  path: '/attendance/report',
  name: 'Attendance Analytics',
  component: ReportAttendance
},
{
  path: '/attendance/edit',
  name: 'Edit',
  component: AttendanceLogEdit
},
{
  path: '/attendance/fixall',
  name: 'Fix All',
  component: AttendanceLogFixAll
},
{
  path: '/swipes',
  exact: true,
  name: 'Swipes',
  component: SwipeList
},
{
  path: '/swipes/resolve',
  name: 'Details',
  component: SwipeResolve
},
{
  path: '/swipes/resolveall',
  name: 'Resolve All',
  component: SwipeResolveAll
},
{
  path: '/swipes/swipecard',
  name: 'Swipe a Card',
  component: SwipeCard
},
{
  path: '/sessionqr',
  name: 'Session QR',
  component: SwipeQRLanding,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},
{
  path: '/swipes/swipeqr',
  name: 'Show Session QR',
  component: SwipeQRShow,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},
{
  path: '/swipes/printqr',
  name: 'Print Session QR',
  component: SwipeQRPrint,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},
{
  path: '/checkinstations',
  exact: true,
  name: 'Check-In Stations',
  component: CheckInStationList
},
{
  path: '/checkinstations/edit',
  name: 'Edit',
  component: CheckInStationEdit
},
{
  path: '/notifications',
  exact: true,
  name: 'Push Notifications',
  component: AnnouncementList,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},
{
  path: '/notifications/exhibitor',
  exact: true,
  name: 'Push Notifications',
  component: AnnouncementListExhibitor,
  packages: [EXHIBITOR_PACKAGE]
},
{
  path: '/announcements',
  exact: true,
  name: 'Announcements',
  component: AnnouncementListMine
},
{
  path: '/notifications/edit',
  name: 'Edit',
  component: AnnouncementEdit
},
{
  path: '/announcements/view',
  name: 'View Announcement',
  component: AnnouncementView
},

// Import
{
  path: '/import/upload',
  exact: true,
  name: 'Import Data',
  component: ImportUpload
},
{
  path: '/import/upload/learn',
  name: 'How To Import Data',
  component: ImportLearn
},

// Export
{
  path: '/export/download',
  name: 'Export Data',
  component: ExportDownload
},

// Interaction
{
  path: '/social/post',
  name: 'Create a Post',
  component: InteractionPost,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},
{
  path: '/social/moderate',
  exact: true,
  name: 'Moderate Posts',
  component: InteractionModerate,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},
{
  path: '/social/gallery',
  exact: true,
  name: 'Gallery',
  component: InteractionGallery,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},
{
  path: '/social/wall',
  exact: true,
  name: 'Social Wall',
  component: InteractionWall,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},  
// Exhibitors
{
  path: '/exhibitors',
  exact: true,
  name: 'Exhibitors',
  component: props => <CompanyList title="Exhibitors" {...props} />,
  packages: [EXHIBITOR_PACKAGE]
}, {
  path: '/leads',
  exact: true,
  name: 'Leads',
  component: LeadList,
  packages: [EXHIBITOR_PACKAGE]
},
{
  path: '/leads/edit',
  name: 'Edit',
  component: LeadEdit
},
{
  path: '/leads/form',
  name: 'Custom Qualifiers',
  component: LeadForm
},
{
  path: '/leads/form-bulk',
  name: 'Custom Qualifiers - Bulk Setup',
  component: LeadFormBulk
},
{
  path: '/leads/emailbroadcast',
  name: 'Email Broadcast',
  component: LeadEmailBroadcast
},
{
  path: '/users/exhibitor administrator',
  name: 'Exhibitor Administrators',
  component: UserList,
  packages: [EXHIBITOR_PACKAGE]
},
{
  path: '/users/exhibitor representative',
  exact: true,
  name: 'Exhibitor Representatives',
  component: UserList,
  packages: [EXHIBITOR_PACKAGE]
},
{
  path: '/leads/rewards',
  exact: true,
  name: 'Prizes',
  component: LeadAwards,
  packages: [EXHIBITOR_PACKAGE]
},
{
  path: '/leads/rewards/topvisitors',
  name: 'Top Leads',
  component: LeadTopVisitors
},
{
  path: '/leads/rewards/drawing',
  name: 'Random Drawing',
  component: LeadDrawing
},
{
  path: '/leads/email',
  name: 'Email',
  component: LeadEmail
},
{
  path: '/ads/leads',
  exact: true,
  name: 'Push Ads',
  component: LeadAds,
  packages: [EXHIBITOR_PACKAGE, ATTENDEE_EXPERIENCE_PACKAGE]
},
{
  path: '/ads/leads/push',
  name: 'Send',
  component: LeadAdsPush
},

//Ads
{
  path: '/ads',
  exact: true,
  name: 'Ad Center',
  component: AdCenter,
  packages: [EXHIBITOR_PACKAGE, ATTENDEE_EXPERIENCE_PACKAGE]
},

//Reports
{
  path: '/analytics',
  exact: true,
  name: 'Analytics',
  component: AnalyticsDashboard
},
{
  path: '/analytics/social',
  exact: true,
  name: 'Social Activity Analytics',
  component: ReportSocial,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},
{
  path: '/analytics/leads',
  exact: true,
  name: 'Leads Analytics',
  component: ReportLeads,
  packages: [EXHIBITOR_PACKAGE]
},
{
  path: '/analytics/interested',
  exact: true,
  name: 'Interested Attendees',
  component: InterestedAttendees,
  packages: [EXHIBITOR_PACKAGE]
},
{
  path: '/analytics/session',
  exact: true,
  name: 'Session',
  component: ReportWorkshop
},
{
  path: '/analytics/sessionabsentees',
  name: 'Session Absentees',
  component: ReportWorkshopAbsentees
},
{
  path: '/analytics/reps',
  name: 'Reps Activity',
  component: ReportReps
},
{
  path: '/analytics/attendee',
  name: 'Attendee Attendance',
  component: ReportAttendee
},
{
  path: '/analytics/registration/attendee',
  name: 'Attendee Registration',
  component: RegistrationReport,
  packages: [PRE_CONFERENCE_PACKAGE]
},
{
  path: '/analytics/registration/exhibitor',
  name: 'Exhibitor Registration',
  component: RegistrationReport,
  packages: [PRE_CONFERENCE_PACKAGE, EXHIBITOR_PACKAGE]
},

// Surveys
{
  path: '/surveys',
  exact: true,
  name: 'Surveys',
  component: SurveyList,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},
{
  path: '/surveys/edit',
  exact: true,
  name: 'Edit',
  component: SurveyEdit
},
{
  path: '/surveys/copy',
  exact: true,
  name: 'Copy',
  component: SurveyEdit
},
{
  path: '/surveys/answered',
  exact: true,
  name: 'Answered',
  component: SurveyAnswered,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},
{
  path: '/surveys/results',
  name: 'Results',
  component: SurveyResults
},
{
  path: '/polls',
  exact: true,
  name: 'Polls',
  component: SurveyList,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},
{
  path: '/polls/edit',
  exact: true,
  name: 'Edit',
  component: SurveyEdit
},
{
  path: '/polls/copy',
  exact: true,
  name: 'Copy',
  component: SurveyEdit
},
{
  path: '/polls/results',
  name: 'Results',
  component: SurveyResults
},
{
  path: '/polls/answered',
  exact: true,
  name: 'Answered',
  component: SurveyAnswered,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},
// My Session Evaluations
{
  path: '/mysessionevaluation',
  exact: true,
  name: 'My Session Evaluations',
  component: PresenterSessionEvaluation,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},
{
  path: '/mysessionevaluation/sessions',
  exact: true,
  name: 'Sessions',
  component: PresenterSessionEvaluationSessions
},
{
  path: '/mysessionevaluation/analytics',
  exact: true,
  name: 'Analytics',
  component: PresenterSessionEvaluationAnalytics
},
{
  path: '/mysessionevaluation/analytics/session',
  exact: true,
  name: 'Report',
  component: PresenterSessionEvaluationSessionReport
},

// Session Evaluation
{
  path: '/sessionevaluation',
  exact: true,
  name: 'Session Evaluation',
  component: SessionEvaluation,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},
{
  path: '/sessionevaluation/edit',
  exact: true,
  name: 'Edit',
  component: SessionEvaluationEdit
},
{
  path: '/sessionevaluation/customize',
  exact: true,
  name: 'Customize',
  component: SessionEvaluationEdit
},
{
  path: '/sessionevaluation/summaryreport',
  exact: true,
  name: 'Sessions Summary Report',
  component: SessionEvaluationSummaryReport,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},
{
  path: '/sessionevaluation/sessions',
  exact: true,
  name: 'Sessions',
  component: SessionEvaluationSessions
},
{
  path: '/sessionevaluation/analytics/session',
  exact: true,
  name: 'Report',
  component: SessionEvaluationSessionReport
},
{
  path: '/sessionevaluation/analytics',
  exact: true,
  name: 'Analytics',
  component: SessionEvaluationAnalytics
},

// Newsletters
{
  path: '/newsletters',
  exact: true,
  name: 'Newsletters',
  component: NewsletterList
},
{
  path: '/newsletters/edit',
  name: 'Edit',
  component: NewsletterEdit
},
{
  path: '/newsletters/send',
  name: 'Send',
  component: NewsletterSend
},

// Advanced options
{
  path: '/discountcodes',
  exact: true,
  name: 'Discount Coupons',
  component: DiscountCodeList
},
{
  path: '/discountcodes/edit',
  name: 'Edit',
  component: DiscountCodeEdit
},
{
  path: '/emaildeliverylogs',
  name: 'Email Delivery Logs',
  component: EmailDeliveryLogList
},
{
  path: '/devices',
  exact: true,
  name: 'Devices',
  component: DeviceList
},
{
  path: '/devices/message',
  name: 'Send Message',
  component: DeviceMessage
},
{
  path: '/usersessions',
  name: 'Active Sessions',
  component: SessionList
},
{
  path: '/users/staffmessaging',
  name: 'Staff Messaging',
  component: StaffMessaging
},
{
  path: '/search',
  exact: true,
  name: 'Advanced Search',
  component: AdvancedSearch
},

// Mobile ads
{
  path: '/ads/attendee',
  exact: true,
  name: 'Attendee Portal Ads',
  component: MobileAdList,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE, EXHIBITOR_PACKAGE]
},
{
  path: '/ads/attendee/edit',
  name: 'Edit',
  component: MobileAdEdit
},
{
  path: '/ads/attendee/summary',
  name: 'Summary',
  component: MobileAdSummary
},

// Attendee site
{
  path: '/attendees',
  exact: true,
  name: 'People',
  component: AttendeeList,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},
{
  path: '/ranking',
  exact: true,
  name: 'Ranking',
  component: RankingList
},
{
  path: '/presenters',
  exact: true,
  name: 'Presenters',
  component: PresenterList
},
{
  path: '/registrations',
  exact: true,
  name: 'Registrations',
  component: AttendeeSiteWorkshopList
},
{
  path: '/registrations/edit',
  exact: true,
  name: 'Edit Sessions',
  component: AttendeeSiteEditWorkshops
},
{
  path: '/messages',
  exact: true,
  name: 'Messages',
  component: ChatSummary,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},
{
  path: '/messages/conversation',
  exact: true,
  name: 'Conversation',
  component: Chat
},
{
  path: '/post',
  exact: true,
  name: 'Post',
  component: PostView
},
{
  path: '/sponsors',
  exact: true,
  name: 'Sponsors',
  component: props => <CompanyList title="Sponsors" hideSponsorsFilter={true} onlySponsors={true} {...props} />
}, {
  path: '/appointments',
  exact: true,
  name: 'Appointments',
  component: AppointmentList,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},
{
  path: '/appointments/edit',
  exact: true,
  name: 'Edit Appointment',
  component: AppointmentEdit
},
{
  path: '/appointments/view',
  exact: true,
  name: 'View Appointment',
  component: AppointmentView
},
{
  path: '/mysurveys',
  exact: true,
  name: 'Surveys',
  component: MySurveyList
},
{
  path: '/mypolls',
  exact: true,
  name: 'Polls',
  component: MySurveyList
},
{
  path: '/mysurveys/answer',
  name: 'Answer',
  component: MySurveyAnswer
},
{
  path: '/mypolls/answer',
  name: 'Answer',
  component: MySurveyAnswer
},
{
  path: '/mysurveys/results',
  name: 'Results',
  component: SurveyResults
},
{
  path: '/mypolls/results',
  name: 'Results',
  component: SurveyResults
},
{
  path: '/myattendance',
  exact: true,
  name: 'Attendance',
  component: AttendeeSiteLogList
},
{
  path: '/myattendance/view',
  exact: true,
  name: 'View Log',
  component: AttendeeSiteLogView
},
{
  path: '/notes',
  exact: true,
  name: 'Notes',
  component: NoteList
},
{
  path: '/notes/edit',
  exact: true,
  name: 'Edit Note',
  component: NoteEdit
},
{
  path: '/files',
  exact: true,
  name: 'My Files',
  component: FileList
},
{
  path: '/evaluations',
  exact: true,
  name: 'Session Evaluations',
  component: EvaluationsList
},
{
  path: '/evaluations/answer',
  name: 'Answer',
  component: EvaluationsAnswer
},
{
  path: '/exhibitors/virtual',
  name: 'Virtual Exhibit Hall',
  component: VirtualExhibitHall
},

// License plans
{
  path: '/accounts/licenseplans',
  exact: true,
  name: 'License Plans',
  component: PlanList
},
{
  path: '/accounts/licenseplans/edit',
  name: 'Edit',
  component: PlanEdit
},

// License plans
{
  path: '/accounts/licensepackages',
  exact: true,
  name: 'License Packages',
  component: PackageList
},
{
  path: '/accounts/licensepackages/edit',
  name: 'Edit',
  component: PackageEdit
},

//Wheels
{
  path: '/leads/rewards/prizewheels',
  exact: true,
  name: 'Prize Wheels',
  component: PrizeWheelsList
},
{
  path: '/leads/rewards/prizewheels/edit',
  name: 'Edit',
  component: PrizeWheelEdit
},
{
  path: '/tickets/spin',
  name: 'Spin the Wheel',
  component: PrizeWheelSpin
},

//Prize
{
  path: '/leads/rewards/prizes',
  exact: true,
  name: 'Prizes',
  component: PrizesList
},
{
  path: '/leads/rewards/prizes/edit',
  name: 'Edit',
  component: PrizeEdit
},
{
  path: '/tickets/prize',
  name: 'Prize',
  component: PrizeDetail
},

//Ticket
{
  path: '/tickets',
  name: 'My Tickets',
  component: TicketList
},

//Ticket
{
  path: '/leads/rewards/winners',
  name: 'Winners',
  component: VictoryList
},

//About
{
  path: '/about',
  name: 'About',
  component: About
},

// Schedule at a Glance List page
{
  path: '/schedule',
  exact: true,
  name: 'Schedule',
  component: ScheduleList
},

// Schedule at a Glance Edit page
{
  path: '/schedule/edit',
  exact: true,
  name: 'Edit',
  component: ScheduleEdit
},

// Schedule at a Glance Appearance page
{
  path: '/schedule/appearance',
  exact: true,
  name: 'Appearance',
  component: ScheduleAppearance
},

// Conference Evaluation Dashboard
{
  path: '/conferenceevaluation/dashboard',
  exact: true,
  name: 'Conference Evaluation Dashboard',
  component: ConferenceEvaluationDashboard,
},

// Conference Evaluation List
{
  path: '/conferenceevaluation/dashboard/manage',
  exact: true,
  name: 'Conference Evaluation',
  component: ConferenceEvaluationList,
},

/* Creates a Conference Evaluation */
{
  path: '/conferenceevaluation/dashboard/manage/edit',
  exact: true,
  name: 'Edit',
  component: SurveyEdit
},

/* Directs the user to a list of Conference Evaluation results */
{
  path: '/conferenceevaluation/dashboard/answered',
  exact: true,
  name: 'Answered',
  component: SurveyAnswered,
  packages: [ATTENDEE_EXPERIENCE_PACKAGE]
},
/* Displays the results for a specific Conference Evaluation */
{
  path: '/conferenceevaluation/dashboard/results',
  name: 'Results',
  component: SurveyResults
},

/* Displays the results for a Conference Evaluation on the attendee portal */
{
  path: '/myconferenceevaluation/results',
  name: 'Results',
  component: SurveyResults
},
/* Lists Conference Evaluation on the attendee portal */
{
  path: '/myconferenceevaluation',
  exact: true,
  name: 'Conference Evaluation',
  component: MySurveyList
},
/* Directs the user to answer a Conference Evaluation on the attendee portal */
{
  path: '/myconferenceevaluation/answer',
  name: 'Answer',
  component: MySurveyAnswer
},
// Workshop Transactions
{
  path: '/workshop/transactions',
  name: 'Session Reg. Transactions',
  component: WorkshopTransactionList,
  packages: [PRE_CONFERENCE_PACKAGE]
},
// Exhibit Booths
{
  path: '/exhibitbooth/floors',
  name: 'Booth Reservation',
  component: ExhibitBoothList,
  packages: [EXHIBITOR_PACKAGE]
},
{
  path: '/exhibitbooth/edit',
  name: 'Edit Booth Reservation',
  component: ExhibitBoothEdit,
  packages: [EXHIBITOR_PACKAGE]
},
// Stripe
{
  path: '/stripe/connect',
  name: 'Stripe Connect',
  component: StripeConnect
},
// Workshop Proposal
{
  path: '/sessions/proposals',
  exact: true,
  name: 'Session Proposals',
  component: WorkshopProposalList,
  packages: [PRE_CONFERENCE_PACKAGE]
},
{
  path: '/sessions/proposals/form',
  name: 'Form',
  component: WorkshopProposalForm,
  packages: [PRE_CONFERENCE_PACKAGE]
},
{
  path: '/sessions/proposals/share',
  name: 'Share',
  component: WorkshopProposalShare, 
  packages: [PRE_CONFERENCE_PACKAGE]
},
{
  path: '/sessions/proposals/edit',
  name: 'Edit Session Proposal',
  component: WorkshopProposalEdit,
  packages: [PRE_CONFERENCE_PACKAGE]
},
{ 
  path: '/sessions/proposals/schedule',
  name: 'Schedule Builder',
  component: WorkshopProposalScheduleBuilder,
  packages: [PRE_CONFERENCE_PACKAGE]
}
];

export default routes;
