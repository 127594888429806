import React, { Component } from 'react';

import ImageButton from './ImageButton';

class FinishButton extends Component {

  render(){
    return (
      <ImageButton
        type={this.props.type}
        onClick={this.props.onSave}
        processing={this.props.finishing ? this.props.finishingText : null}
        className={this.props.className}
        color={this.props.newColor ?? "success"}
        size={this.props.size}
        icon={this.props.icon}>
        {this.props.children}
      </ImageButton>
    );
  }
}

FinishButton.defaultProps = {
  children: "Save Changes",
  finishingText: "Saving...",
  icon: "fas fa-clipboard-check",
  className: ""
}

export default FinishButton;
