export const chart_bar = "fas fa-chart-bar"
export const chart_pie = "fas fa-chart-pie"
export const message = "fas fa-comment"
export const private_message = "far fa-comment-dots"
export const user = "fas fa-user"
export const mobile = "fas fa-mobile-alt"
export const date = "far fa-calendar-alt"
export const clock = "far fa-clock"
export const list = "fas fa-list"
export const send = "far fa-paper-plane"
export const create = "fas fa-plus"
export const fix = "fas fa-wrench"
export const payment = "far fa-credit-card"
export const caret = "fas fa-angle-right"
export const help = "far fa-question-circle"
export const share = "fas fa-share-alt"
export const design = "fas fa-pencil-ruler"
export const password = "fas fa-key"
export const like_solid = "fas fa-thumbs-up"
export const like = "far fa-thumbs-up"
export const dashboard = "fas fa-tachometer-alt"
export const conference = "fas fa-home"
export const setup = "fas fa-cog"
export const workshop = "fas fa-chalkboard-teacher"
export const workshops = workshop
export const video = "fas fa-video"
export const handout = "fas fa-book-open"
export const goodies = handout
export const map = "fas fa-map"
export const maps = map
export const gallery = "fas fa-images"
export const company = "fas fa-building"
export const companies = company
export const company_limits = "far fa-hand-paper"
export const users = "fas fa-users"
export const administrator = "fas fa-user-tie"
export const presenter = "fas fa-bullhorn"
export const moderator = "fas fa-user-shield"
export const operator = user
export const attendee = "fas fa-user-graduate"
export const user_edit = "fas fa-user-edit"
export const group = users
export const groups = group
export const online_registration = "fas fa-edit"
export const registrations = online_registration
export const check_in = "fas fa-sign-in-alt"
export const sign_in = check_in
export const back = "fas fa-backward"
export const loading = "fa fa-circle-notch fa-spin"
export const sad_face = "far fa-frown"
export const notification = "fas fa-bell"
export const notifications = notification
export const contactnotifications = notifications
export const social_feed = "fas fa-comments"
export const comment = "fa fa-comment-alt"
export const regular_comment = "far fa-comment-alt"
export const invoice = "fas fa-file-invoice-dollar"
export const invoices = invoice
export const badge = "fas fa-address-card"
export const badges = badge
export const edit = "fas fa-edit"
export const print = "fas fa-print"
export const email = "fas fa-envelope"
export const print_qr = "fas fa-qrcode"
export const attendance_mode = "fas fa-door-open"
export const attendance = "fas fa-user-check"
export const logs = attendance
export const swipe = "fa fa-credit-card"
export const swipes = swipe
export const check_in_station = "fas fa-desktop"
export const checkinstations = check_in_station
export const announcement = mobile
export const session_evaluation = "fas fa-star"
export const leads = users
export const awards = "fas fa-award"
export const representative = user
export const polls = "fas fa-chart-bar"
export const surveys = "fas fa-poll"
export const survey_results = chart_bar
export const poll_results = chart_bar
export const newsletter = "fas fa-newspaper"
export const ad = "fa fa-bullseye"
export const certificate = "fas fa-medal"
export const certificates = certificate
export const transcript = "fas fa-scroll"
export const transcripts = transcript
export const social = "fas fa-mobile-alt"
export const analytics = chart_pie
export const advanced_options = "fas fa-cogs"
export const import_data = "fas fa-upload" 
export const export_data = "fas fa-download" 
export const moderate_interactions = "fas fa-shield-alt"
export const discount_code = "fas fa-tag"
export const discountcodes = discount_code
export const user_sessions = users
export const staff_message = message
export const device = mobile
export const devices = mobile
export const settings = "fas fa-wrench"
export const presenterPortal = "fas fa-chalkboard-teacher"
export const appearance = "fas fa-palette"
export const room = "fa fa-map-marker"
export const rooms = room
export const track = "fa fa-tasks"
export const tracks = track
export const quiz = "fas fa-poll-h"
export const sponsorship = "fas fa-handshake"
export const sponsorships = sponsorship
export const custom_fields = "fas fa-asterisk"
export const post_to_social_feed = message
export const paypal = "fab fa-paypal"
export const credit_card = "far fa-credit-card"
export const bank = "fas fa-university"
export const check = "fas fa-money-check"
export const cash = "far fa-money-bill-alt"
export const mobileads = ad
export const contact = "far fa-address-book"
export const contacts = contact
export const copy = "far fa-copy"
export const preview = "fas fa-eye"
export const info = "fas fa-info"
export const info_circle = "fas fa-info-circle"
export const appointment = "fas fa-calendar-check"
export const cancel_appointment = "far fa-calendar-times"
export const questionnaires = "fas fa-star-half-alt"
export const note = "fas fa-sticky-note"
export const file = "far fa-file-alt"
export const virtual_exhibit = "fas fa-store"
export const warning = "fas fa-exclamation-circle"
export const success = "far fa-check-circle"
export const cancel = "far fa-times-circle"
export const error = "fas fa-times"
export const prize = "fas fa-trophy"
export const prizes = prize
export const ticket = "fas fa-ticket-alt"
export const credits = awards
export const puzzle_piece = "fas fa-puzzle-piece"
export const calendar_week = "fas fa-clipboard-list"
export const cloud_upload = "fas fa-cloud-upload-alt"
export const cloud_download = "fas fa-cloud-download-alt"
export const sms = "fas fa-sms"
export const conference_evaluation = "fas fa-vote-yea"
export const reset = "fa fa-retweet"
export const publish = "fa fa-publish"
export const tablet = "fas fa-tablet-alt"
export const double_check ="fas fa-check-double"
export const child ="fas fa-child"
export const workshopproposals = "fas fa-chalkboard-teacher"
export const exhibitboothfloors = "fas fa-store"
export const workshoptransactions = online_registration
export const exhibitorpurchaseorders = list
export const appointments = appointment

